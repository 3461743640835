import Paragraphs from '../../../Atoms/Paragraphs'
import PropTypes from 'prop-types'
import { IoIosArrowDown } from 'react-icons/io'
import { ContainerText, Arrow, ContainerFaq, ContainerTextClose } from './style'

const AccordionSlide = ({ faq, index, onClickFaq }) => {
  return (
    <>
        <ContainerFaq key={index} active={faq.active} onClick={() => onClickFaq(index)}>
            <Paragraphs>{faq.title}</Paragraphs>
            <Arrow rotate = {faq.active} >
               <IoIosArrowDown/>
            </Arrow>
        </ContainerFaq>
        {faq.active
          ? <ContainerText>
            <Paragraphs className='text-primary'>{faq.content}</Paragraphs>
           </ContainerText>
          : <ContainerTextClose active = {faq.active}> <Paragraphs className='text-primary'>{faq.content}</Paragraphs></ContainerTextClose>}
    </>
  )
}

export default AccordionSlide

AccordionSlide.propTypes = {
  faq: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClickFaq: PropTypes.func.isRequired
}
