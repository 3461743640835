import React from 'react'
import PropTypes from 'prop-types'
import PageTitle from '../Molecules/PageTitle/PageTitle'

const Page = ({ children, pageTitle, className, id }) => {
  return (
    <div id={id} className={className}>
        {pageTitle && <PageTitle title={pageTitle} />}
        {children}
    </div>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string
}

export default Page
