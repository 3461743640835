import React from 'react'
import PropTypes from 'prop-types'
import { Link as NavLink } from 'react-router-dom'
import Paragraphs from '../Paragraphs/Paragraphs'

const Link = ({ url, name, className, transform, children, borderBottom, size }) => {
  if (!url) { return null }
  return (
    <div id={`link-${name}`} className=" min-w-max">
      <NavLink to={url} className={className + ' h-full'} >

          {name && <Paragraphs size={size} className={borderBottom && 'border-b-2 border-white'} transform={transform}>{name}</Paragraphs>}
          {children}

      </NavLink>
    </div>
  )
}

Link.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  transform: PropTypes.string,
  children: PropTypes.node,
  borderBottom: PropTypes.bool,
  size: PropTypes.string
}

export default Link
