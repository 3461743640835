import { navigationAccount } from '../../../infrastructure/constans/navigationAccount/navigattionAccount'
import PropTypes from 'prop-types'
import Paragraphs from '../../Atoms/Paragraphs'
import Link from '../../Atoms/Link'

const NavigationAccount = ({ onClickNavigation }) => {
  return (
        <div className="  sm:block lg:border-r-[1px]  border-slate-200 lg:w-[330px]">
        <ul className='navigation-account   lg:border-none   flex flex-col'>
          {navigationAccount.map(item => (
            <div id={`account-link-${item.id}`} onClick={() => onClickNavigation(item.id)} key={item.id} className='flex items-center border-b-[1px] border-slate-200 w-[95%] p-[9px]'>
              <Link url={item.url}><span className='flex items-center gap-2'>{item.icon}<Paragraphs className='cursor-pointer'>{item.name}</Paragraphs></span></Link>
            </div>
          ))}
        </ul>
      </div>
  )
}

export default NavigationAccount

NavigationAccount.propTypes = {
  onClickNavigation: PropTypes.func.isRequired
}
