/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import Paragraphs from '../../Atoms/Paragraphs'
import Review from '../Review'
import { useInsure } from '../../../infrastructure/hooks/useInsure'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import Modal from '../Modal'
import Button from '../../Atoms/Button'
import { differenceInMonths, differenceInDays, format } from 'date-fns'
import { TiDelete } from 'react-icons/ti'
import Layout from '../../Layout'
import { ENDPOINT } from '../../../infrastructure/api/configuration'
import { useMutation } from '../../../infrastructure/hooks/useMutation'

const PolizaDetail = () => {
  const [openAlert, setOpenAlert] = useState(false)
  const [daysForExpiration, setDaysForExpiration] = useState(null)
  const [showMessage, setShowMessage] = useState(true)
  const [readMessage] = useMutation(ENDPOINT.READ)
  const [expired, setExpired] = useState('')
  const [data, setData] = useState({})
  const { id } = useParams()
  const { getDetail } = useInsure()
  useEffect(() => {
    const expirationDate = new Date(data.expiration_date)
    const unixTimestamp = Math.floor(expirationDate.getTime() / 1000)
    const now = Math.floor(new Date().getTime() / 1000)
    if (unixTimestamp < now) {
      setExpired('Caducada')
    } else {
      setExpired('Activa')
    }
  }, [data?.expiration_date])

  const getDetailInsure = async () => {
    const { success, data, error } = await getDetail(id)
    if (success) {
      setData(data)
    }
    if (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    if (id) {
      getDetailInsure()
    }
  }, [id])

  const now = new Date()
  const end = new Date(data?.expiration_date)
  const diference = differenceInMonths(end, now)
  const diferenceInDays = differenceInDays(end, now)

  const navigate = useNavigate()

  const { deleteInsure } = useInsure()

  useEffect(() => {
    if (expired) {
      if (expired === 'Activa') {
        if (diference < 3) {
          setDaysForExpiration(diferenceInDays)
        }
      }
    }
  }, [data])

  const handleDelete = async () => {
    const { success, error } = await deleteInsure(data?.id)
    if (success) {
      toast.success('Poliza eliminada correctamente')
      navigate('/')
    }
    if (error) {
      console.log(error)
    }
  }

  const onReadMessage = async () => {
    const { success, error } = await readMessage({
      method: 'get',
      addToUrl: `/${id}`
    })
    if (success) {
      toast.success('Mensaje eliminado correctamente')
      setShowMessage(false)
    }
    if (error) {
      toast.error(error)
    }
  }

  const alertDelete = () => {
    setOpenAlert(true)
  }

  const handleClose = () => {
    setOpenAlert(false)
  }

  return (
    <div className='pl-5 min-h-[500px] mx-auto w-full gap-5 flex flex-col' >
    <div className="text-primary flex flex-col gap-5">
      <div
        onClick={alertDelete}
        className="absolute right-5 top-5 border-[1px] bg-red-400 border-alert cursor-pointer transition-all hover:bg-red-600 text-secondary text-sm px-5 py-1"
      >
        Eliminar
      </div>
      <Modal onCloseModal={handleClose} showModal={openAlert}>
        <div className=" p-5 flex flex-col gap-5 ">
          <Paragraphs className="text-primary" size="md" weight="semibold">
            ¿Está seguro de eliminar la poliza?
          </Paragraphs>
          <div className="flex justify-evenly">
            <Button
              onClick={handleDelete}
              mode="orange"
              size="small"
              label="Ok"
            />
            <Button onClick={handleClose} size="small" label="Cancelar" />
          </div>
        </div>
      </Modal>
      {data?.custom_message && data?.custom_message_read !== 1 && showMessage && (
        <div
          id='custom-message-insure'
          className="bg-red-100 rounded-lg py-5 relative px-6  text-base text-red-700 mb-3 sm:max-w-[650px]"
          role="alert"
        >
          <Paragraphs>{data?.custom_message}</Paragraphs>
          <div onClick = {onReadMessage} className="absolute cursor-pointer top-1 right-1">
            <TiDelete />
          </div>
        </div>
      )}
      <div className="flex gap-5 flex-wrap max-w-[400px]">
        <Paragraphs>
          <span className="font-semibold">Fecha alta</span> : {data?.initial_date && format(new Date(data?.initial_date), 'dd-MM-yyyy')}
        </Paragraphs>
        <Paragraphs>
          <span className="font-semibold">Fecha de vencimiento</span>:{' '}
          {data?.expiration_date && format(new Date(data?.expiration_date), 'dd-MM-yyyy')}
        </Paragraphs>
        {daysForExpiration && expired === 'Activa' && (
          <span
            className={`text-xs bottom-0 text-secondary p-1 rounded-md ${
              diference < 1 ? 'bg-red-500' : 'bg'
            }`}
          >
            {`Vence en ${daysForExpiration} dias`}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-5">
        <Paragraphs className="" weight="bold" size="xl">
          {data?.agent?.company}
        </Paragraphs>
        <div className="">
          <ul className=" gap-3 max-w-[400px] grid grid-cols-2">
            <Paragraphs className="flex flex-col">
              Estado
              <span
                className={`font-semibold rounded-md text-secondary w-min p-1 ${
                  expired === 'Activa' ? 'bg-green-500' : 'bg-red-500'
                }`}
              >
                {expired}
              </span>
            </Paragraphs>
            <Paragraphs className="flex flex-col">
              Póliza Nº<span className="font-semibold">{data?.insurance_number}</span>
            </Paragraphs>
            <Paragraphs className="flex flex-col">
              Fecha de vencimiento
              <span className="font-semibold">{data?.expiration_date && format(new Date(data?.expiration_date), 'dd-MM-yyyy')}</span>
            </Paragraphs>
            <Paragraphs className="flex flex-col">
              Tipo de seguro
              <span className="font-semibold">{data?.insurance_type?.name}</span>
            </Paragraphs>
            <Paragraphs className="flex flex-col">
              Coste<span className="font-semibold">{data?.cost}€</span>
            </Paragraphs>
            <Paragraphs className="flex flex-col">
              Compañia aseguradora<span className="font-semibold">{data?.insurer?.name}€</span>
            </Paragraphs>
            <Paragraphs className="flex flex-col">
              Forma de pago<span className="font-semibold">{data?.payment}</span>
            </Paragraphs>
            <Paragraphs className="flex flex-col overflow-auto">
              Banco<span className="font-semibold">{data?.bank || 'no existe'}</span>
              IBAN<span className="font-semibold">{data?.iban || 'no existe'}</span>
            </Paragraphs>

          </ul>
        </div>
        <div id="form-review-id" className="w-full">
          <Review
            detailPoliza={data}
            title="Dejanos tu opinión"
            subTitle="Si desea dejarnos un comentario acerca de su póliza, por favor, escríbalo a continuación."
            titleRating="Valoración de la póliza"
          />
        </div>
      </div>
    </div>
    </div>
  )
}

export default PolizaDetail

PolizaDetail.getLayout = function getLayout (page) {
  return <Layout pageTitle={'Mis Pólizas'}>{page}</Layout>
}

PolizaDetail.propTypes = {
  detailPoliza: PropTypes.object,
  estado: PropTypes.oneOf(['Activa', 'inactive', 'Caducada'])
}
