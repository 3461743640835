/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import ClickAwayListener from 'react-click-away-listener'
import Button from '../../Atoms/Button'
import Paragraphs from '../../Atoms/Paragraphs'
import { useUpload } from '../../../infrastructure/hooks/useUpload'
import { useState, useRef } from 'react'
import { TiDelete } from 'react-icons/ti'
import Loading from '../Loading'

const AddPoliza = ({ showModal, onCloseModal }) => {
  const [uploadFile, { loading }] = useUpload()
  const [file, setFile] = useState([])
  const [succesMessage, setSuccesMessage] = useState('')
  const hiddenFileInput = useRef(null)

  const handleClickAway = () => {
    onCloseModal()
  }

  const handleClick = event => {
    hiddenFileInput.current.click()
  }
  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    setFile([...file, fileUploaded])
  }

  const deleteFile = (idx) => {
    const newFile = [...file]
    newFile.splice(idx, 1)
    setFile(newFile)
  }

  const onUploadFile = async () => {
    try {
      const { success } = await uploadFile(file)
      if (success) {
        setSuccesMessage('Acción realizada correctamente, en 48h recibirá un correo de confirmación')
      } else {
        setSuccesMessage('Error al subir archivo')
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={`md:w-[560px] relative max-h-[650px] overflow-y-scroll  min-h-[200px] bg-background flex flex-col items-center justify-center gap-5 ${loading ? 'opacity-50' : null}`}>
            {loading && <div className='absolute z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
                {succesMessage !== ''
                  ? <Paragraphs className='text-primary message-success-add-poliza'>{succesMessage}</Paragraphs>
                  : <><form className='p-8 flex flex-col items-center gap-10'>
                      <Paragraphs className='text-primary md:mb-10' size='3xl' weight='semibold'>Cargar contratos de Seguros</Paragraphs>
                      <div className='flex flex-col items-center j  overflow-y-auto gap-2'>
                          <Button onClick={handleClick} size='medium' label={file.length > 0 ? 'Cargar otro archivo' : 'Cargar archivo'} />
                          {file.length > 0 &&
                          <div className=' gap-1 flex flex-col items-center max-h-[200px] overflow-y-auto' >
                              {file?.map((file, index) => (
                                  <div key={index} className='relative bg-sky-200 p-4 border-[1px] border-sky-300 max-w-[90%]  md:w-[400px]'>
                                      <div onClick={() => deleteFile(index)} className='absolute cursor-pointer hover:text-sky-500 right-1 top-1 text-sky-400'><TiDelete /></div>
                                      <Paragraphs className='text-sky-600' weight='semibold'>
                                          {`${file.name} se a cargado correctamente`}
                                      </Paragraphs>
                                  </div>
                              ))}
                              </div>
                              }

                          <input
                          id='input-add-file'
                              type="file"
                              ref={hiddenFileInput}
                              onChange={handleChange}
                              style={{ display: 'none' }} />
                          <Paragraphs className='text-center'>Formatos admitidos PDF,JPG,PNG,Word</Paragraphs>
                      </div>
                      <Button id='btn-send-files' onClick={onUploadFile} size='medium' mode='orange' transform='uppercase' label='continuar' />
                  </form><div className='bg-secondary text-center p-4'>
                          <Paragraphs className='text-primary text-center' weight='semibold'>Si no dispone de los archivos puede enviar por mail al info@totsafe.es o pueden llarmarnos o escribirnos al (+34) 660 39 83 02</Paragraphs>
                          <p>iconos</p>
                      </div></>
                }

            </div>
        </ClickAwayListener>

  )
}

export default AddPoliza

AddPoliza.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}
