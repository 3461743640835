import Paragraphs from '../../Atoms/Paragraphs'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AvatarCropImage = styled.div`
  width: 150px;
  height: 150px;
  /* border-radius: 100%; */
  overflow: hidden;
  background-image: url(${({ avatar }) => avatar});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

`

const AvatarCard = ({ avatar, position, name }) => {
  return (
    <div className='flex flex-col gap-5'>
    <AvatarCropImage avatar={avatar} />
    <div className='flex flex-col text-center gap-2'>
      <Paragraphs
        className="text-primary"
        weight="semibold"
      >
        {name}
      </Paragraphs>
      <Paragraphs
        className="text-slate-400"
        weight="semibold"
      >
        {position}
      </Paragraphs>
    </div>
  </div>
  )
}

export default AvatarCard

AvatarCard.propTypes = {
  avatar: PropTypes.string,
  position: PropTypes.string,
  name: PropTypes.string
}
