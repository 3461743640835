/**
 * useParagraphs
 * @author @jstarsky
 * @param {*} input
 * @return {Object}
 */
export const useParagraphs = ({
  id,
  fontFamily,
  size,
  transform,
  weight,
  italic,
  extendClassname
}) => {
  /**
   * setFontFamily
   * @author @jstarsky
   * @param {String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-family#header
   */
  const setFontFamily = (input) => {
    switch (input) {
      case 'prompt':
        return 'font-prompt'
      case 'fira':
        return 'font-fira'
      default:
        return ''
    }
  }

  /**
   * setSize
   * @author @jstarsky
   * @param {Numer | String} input
   * @return {String}
   * https://tailwindcss.com/docs/font-size#header
   */
  const setSize = (input) => {
    switch (input) {
      case 'xxxs':
        return ' text-xxxs'
      case 'xxs':
        return ' text-xxs'
      case 'xs':
        return ' text-xs'
      case 'sm':
        return ' text-sm'
      case 'base':
        return ' text-base'
      case 'lg':
        return ' text-lg'
      case 'xl':
        return ' text-xl'
      case '2xl':
        return ' text-2xl'
      case '3xl':
        return ' text-3xl'
      case '4xl':
        return ' text-4xl'
      case '5xl':
        return ' text-5xl'
      case '6xl':
        return ' text-6xl'
      case '7xl':
        return ' text-7xl'
      case '8xl':
        return ' text-8xl'
      case '9xl':
        return ' text-9xl'
      default:
        return ''
    }
  }

  /**
   * setTransform
   * @author @jstarsky
   * @param {String} input transform
   * @return {String}
   * https://tailwindcss.com/docs/text-transform#transforming-text
   */
  const setTransform = (input) => {
    switch (input) {
      case 'uppercase':
        return ' uppercase'
      case 'lowercase':
        return ' lowercase'
      case 'capitalize':
        return ' capitalize'
      default:
        return ''
    }
  }

  /**
   * setWeight
   * @author @jstarsky
   * @param {String} input weight
   * @return {String}
   * https://tailwindcss.com/docs/text-transform#transforming-text
   */
  const setWeight = (input) => {
    switch (input) {
      case 'light':
        return ' font-light'
      case 'medium':
        return ' font-medium'
      case 'bold':
        return ' font-bold'
      case 'book':
        return ' font-book'
      case 'regular':
        return ' font-regular'
      default:
        return ''
    }
  }

  /**
   * setItalic
   * @author @jstarsky
   * @param {String} input italic
   * @return {String}
   * https://tailwindcss.com/docs/text-transform#transforming-text
   */
  const setItalic = (input) => {
    if (input) return ' italic'
    else return ''
  }

  return {
    internalClassName: `${id}${setFontFamily(fontFamily)}${setSize(
      size
    )}${setTransform(transform)}${setWeight(weight)}${setItalic(italic)}${
      extendClassname ? ` ${extendClassname}` : ''
    }`
  }
}
