import Review from '../../../components/Molecules/Review'
import LayoutAccount from '../../../components/Molecules/MyAccount/Layout/Layout'
import Layout from '../../../components/Layout'

const ValoracionPage = () => {
  return (
        <LayoutAccount>
            <div className='p-2 w-[500px] max-w-[95%]  flex-col'>
                <Review titleRating='Valoración General' titleH= 'Dejanos tu opinión'/>
            </div>
        </LayoutAccount>
  )
}

ValoracionPage.getLayout = function getLayout (page) {
  return <Layout pageTitle={'Valoración'}>{page}</Layout>
}

export default ValoracionPage
