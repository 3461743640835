export const INPUTS_REGISTER = [
  {
    id: 1,
    label: 'Nombre de la empresa',
    placeholder: 'Empresa',
    name: 'company',
    typeFor: 1,
    width: 'w-full',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 2,
    label: 'Nombre',
    placeholder: 'Nombre',
    name: 'name',
    typeFor: 2,
    width: 'w-full',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 3,
    label: 'Apellidos',
    placeholder: 'lastname',
    name: 'lastname',
    typeFor: 2,
    width: 'w-full',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 4,
    label: 'Nombre de persona de contacto',
    placeholder: 'Nombre de persona de contacto',
    name: 'name',
    typeFor: 1,
    width: 'w-full ',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 5,
    label: 'CIF/NIF',
    placeholder: 'Cif',
    name: 'cif',
    description: '*Colocar el CIF/NIF de la empresa, tal como aparece en la póliza de seguro',
    width: 'w-full',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 6,
    label: 'Teléfono',
    placeholder: 'Teléfono',
    name: 'phone',
    keyboardType: 'phone-pad',
    width: 'w-full',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 7,
    label: 'Email',
    placeholder: 'Email',
    name: 'email',
    autoCapitalize: 'none',
    keyboardType: 'email-address',
    width: 'w-full',
    required: {
      message: 'El campo es obligatorio'
    }
  },
  {
    id: 8,
    label: 'Contraseña',
    placeholder: 'Contraseña',
    name: 'password',
    secureTextEntry: true,
    pattern: {
      value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      message: 'La contraseña debe contener al menos un carácter especial, y un carácter numérico'
    },
    width: 'w-full',
    type: 'password',
    description: 'Mínimo 8 caracteres y debe contener, numero, letras y un caracter especial'
  }
]
