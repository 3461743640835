import Paragraphs from '../../Atoms/Paragraphs'
import AvatarCard from '../AvatarCard'
import Button from '../../Atoms/Button'
import ContentShadow from '../../Atoms/ContentShadow'
import { IoIosArrowForward } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import Perfil1 from '../../../infrastructure/assets/static/images/perfil1.jpg'
import Perfil2 from '../../../infrastructure/assets/static/images/perfil2.jpg'

const FUNDADORES = [
  {
    name: 'Manel Calendario',
    position: 'Co-Founder',
    avatar: Perfil1
  },
  {
    name: 'Luis Córdova',
    position: 'Co-Founder',
    avatar: Perfil2
  }
]

const BannerAboutTeam = () => {
  const navigate = useNavigate()

  return (
    <ContentShadow id='banner-about-team' className='flex max-w-[1200px] mx-auto  py-10 flex-col gap-10 md:gap-20 items-center'>
      <div className="flex container flex-col gap-10 items-center justify-center">
        <Paragraphs size="7xl" weight="bold" className="text-primary text-center">
          Socios fundadores
        </Paragraphs>
      </div>
     <div className='flex flex-wrap md:flex-row  justify-evenly gap-10 md:gap-20'>
     {FUNDADORES.map((e, i) => <AvatarCard avatar={e.avatar} position={e.position} name={e.name} key={i} />)}
      </div>
      <div className='w-[180px]'>
      <Button onClick={() => navigate('/contacto')} iconRight= {<IoIosArrowForward/>} transform='uppercase' mode='orange' size='medium' label='contáctanos'/>
      </div>
    </ContentShadow>

  )
}

export default BannerAboutTeam
