import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

const Title = ({ title, id }) => {
  return (
    <div id={`title-${id}`}>
        <h2>
            <Paragraphs size='xl' className={'text-primary font-semibold'} weight={'semibold'}>{title}</Paragraphs>
        </h2>
    </div>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default Title
