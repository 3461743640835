import InfoText from '../InfoText'
import image2 from '../../../infrastructure/assets/static/images/image2_us.png'
import image1 from '../../../infrastructure/assets/static/images/image_us.png'

const BannerAboutInfo = () => {
  return (
        <div className=' w-full gap-20 flex flex-col md:p-10 items-center  lg:flex-row lg:items-start   lg:justify-center   '>
            <div className=' w-[550px] h-[550px] hidden lg:block text-secondary relative'>
              <img src = {image2} className='w-[390px]  hidden lg:block lg:absolute right-0 top-0' />
              <img src = {image1} className='w-[350px] absolute hidden lg:block bottom-10' />
            </div>
            <InfoText className=' gap-2 lg:w-[365px] lg:text-start md:mt-20 text-primary' title= '¿Quiénes somos?' description= 'Somos una empresa que ha desarrollado una plataforma digital llamada “TOTSAFE” con la finalidad de concentrar las pólizas de seguros y gestionarlas eficientemente.'/>
        </div>
  )
}

export default BannerAboutInfo
