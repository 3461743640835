import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import Paragraphs from '../../Atoms/Paragraphs'
import styled from '@emotion/styled'
import { IoIosArrowBack } from 'react-icons/io'

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 40;
`

const Modal = ({ id, children, showModal, onCloseModal }) => {
  const modalContent = showModal
    ? (
    <StyledModalOverlay>
    <div
      id={id}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <div className="Modal__content min-w-[300px]  flex -flex-col  shadow-lg bg-white rounded-[6px]">
        <div className="Modal__content__title items-center justify-between  w-full flex">
          {children}
        </div>
      </div>
      <div onClick= {onCloseModal}>
        <Paragraphs className='text-secondary mt-2 flex items-center gap-1 cursor-pointer'><IoIosArrowBack/>Volver</Paragraphs>
      </div>
    </div>
    </StyledModalOverlay>
      )
    : null

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById('modal-wrapper')
  )
}

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  onCloseModal: PropTypes.func,
  showModal: PropTypes.bool
}

export default Modal
