import styled from '@emotion/styled'
import BackgroundImage from '../../../infrastructure/assets/static/images/fondoLogin.jpg'

export const Container = styled.div`
 background-image: url(${BackgroundImage});
 background-size: cover;
 background-position: center;
 min-height: 100vh;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding-bottom: 10px;
 padding-top: 10px;
 position: relative;
 

`
