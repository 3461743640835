import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const dropdown = keyframes`
  from {
    min-height: 0px;
    transform: translateY(-50px);

  }

  to {
    transform: translateY(0px);
    min-height: 50px;
    position: relative;
    
  }
`

const dropdownClose = keyframes`
    from {
        opacity: 1;
        padding: 5px;
        min-height: 50px;
        transform: translateY(0px);
        position: relative;
    }
    to {
        min-height: 0px;
        transform: translateY(-50px);
    }
`

export const ContainerText = styled.div`
padding: 5px;
animation: ${dropdown} 0.3s ease-in-out;
min-height: 50px;
`

export const ContainerTextClose = styled.div`
animation: ${dropdownClose} 0.3s ease-in-out;
min-height: 0px;
opacity: 0;
height: 0px;
`

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }

`

const rotateClose = keyframes`
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
`

export const Arrow = styled.div`
    transform: ${props => props.rotate ? 'rotate(180deg)' : 'rotate(0deg)'};
    animation: ${props => props.rotate ? rotate : rotateClose} 0.3s;
`

const transition = keyframes`
 
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const ContainerFaq = styled.div`
color : ${props => props.active ? '#fff' : '#071A47'};
background-color: ${props => props.active ? '#071A47' : '#fff'};
display: flex;
justify-content: space-between;
padding:20px ;
cursor: pointer;
animation: ${props => props.active ? transition : 'none'} 0.3s;
 
`
