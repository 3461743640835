/* eslint-disable no-unneeded-ternary */
import React, { } from 'react'
import PropTypes from 'prop-types'
import WrapperInput from '../WrapperInput'

const TextInput = ({
  placeholder,
  topEye,
  id,
  name,
  autoComplete,
  label,
  disabled,
  autoFocus,
  labelClassName,
  onBlur,
  onChange,
  register,
  required,
  error,
  description,
  type,
  value,
  flexDirection,
  className,
  hideLabel,
  widthLabel,
  errorPasss
}) => {
  return (
    <WrapperInput
      label={label}
      required={required}
      error={error}
      description={description}
      name={name}
      className={className}
      id={id}
      type={type}
      errorPasss={errorPasss}
      widthLabel={widthLabel}
      hideLabel={hideLabel}
      flexDirection={flexDirection}
      labelClassName={labelClassName}>

      <input
        name={name}
        type={type}
        required={require}
        id={id}
        ref={register}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className=" focus:ring-indigo-500 px-4 focus:border-indigo-500 block w-full h-full shadow-sm sm:text-sm"
        {...register}
      />
    </WrapperInput>
  )
}

TextInput.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  register: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  flexDirection: PropTypes.string,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthLabel: PropTypes.string,
  errorPasss: PropTypes.string,
  topEye: PropTypes.string

}

TextInput.defaultProps = {
  register: {},
  hideLabel: false
}

export default TextInput
