/**
 *
 * @author ehernandez
 * @param {*} mode
 * @param {*} rounded
 * @param {*} uppercase
 * @returns {React.Component}
 */
export const useBtn = ({ mode, rounded, uppercase }) => {
  /**
     * Ontienen las clases por defecto segun el modo
     * @author   ehernandez --> nombre del creador
     * @param    {boolean} disabled
     * @return   {string}
     */
  const classFromMode = (disabled) => {
    let classes =
        'flex items-center justify-center px-6 items-center w-full h-[30px] rounded-[4px] '

    switch (mode) {
      case 'primary':
        classes += `bg-primary opacity-90 ${
            !disabled && 'hover:opacity-100'
          } text-white`
        break

      case 'white':
        classes += `bg-white opacity-90 ${
            !disabled && 'hover:opacity-100'
          } text-black`
        break
      case 'orange':
        classes += 'text-white bg-orange'
        break

      case 'transparent':
        classes += `bg-transparent ${
            !disabled && 'hover:bg-tertiary hover:text-white'
          } text-tertiary border-2 border-tertiary`
        break

      case 'transparent-white-border':
        classes += `bg-transparent ${
            !disabled &&
            'hover:bg-white hover:bg-opacity-10 hover:text-white'
          } text-primary border-2 border-white`
        break

      case 'expansible':
        classes +=
            'bg-transparent text-primary border-l border-t border-r border-primary text-secondary expansible'
        break

      default:
        break
    }

    if (rounded) {
      classes += 'rounded-md'
    }

    if (disabled) {
      classes += ' bg-opacity-50'
    }

    if (uppercase) {
      classes += ' uppercase'
    }

    return classes
  }

  return { classFromMode }
}
