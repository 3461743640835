import styled from 'styled-components'

export const ContainerBannerHome = styled.div`
background-image: url(${props => props.image});
background-size: cover;
background-position: center;

@media (max-width: 768px) {
    // background opacity
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: multiply;
    
}


`
