import Paragraphs from '../../Atoms/Paragraphs'
import ContentShadow from '../../Atoms/ContentShadow'
const PartnersAbout = () => {
  const n = 1

  return (
    <div className=" p-8 flex items-center gap-5 flex-col ">
      <Paragraphs className="text-primary" weight="bold" size="6xl">
        Partners
      </Paragraphs>
      <div className="flex p-5 flex-wrap gap-10 items-center justify-center">
        {[...Array(n)].map((e, i) => (
          <ContentShadow
            id={`patern-about-${i}`}
            key={i}
            className="w-[160px] py-10 flex items-center justify-center px-4"
          >
            <img
              src={require('../../../infrastructure/assets/static/images/ersm.png')}
            />
          </ContentShadow>
        ))}
      </div>
    </div>
  )
}

export default PartnersAbout
