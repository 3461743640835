import ContentShadow from '../../Atoms/ContentShadow'
import MiniCardLogo from '../../Atoms/MiniCardLogo'
import Paragraphs from '../../Atoms/Paragraphs/Paragraphs'
import logo1 from '../../../infrastructure/assets/static/logosInfoCard/logo_card1.png'
import logo2 from '../../../infrastructure/assets/static/logosInfoCard/logo_card2.png'
import logo3 from '../../../infrastructure/assets/static/logosInfoCard/logo_card3.png'
import logo4 from '../../../infrastructure/assets/static/logosInfoCard/logo_card4.png'
import ImageBack from '../../../infrastructure/assets/static/images/image_infocard.png'
// import Button from '../../Atoms/Button/Button'
import BackgroundImage from '../../../infrastructure/assets/static/images/logo_background.png'

// TODO hacer arreglo de array para los minicardlogo

const INFO_CARDS = [
  {
    text: 'Te ayudamos a unificar tus seguros',
    url: logo1
  },
  {
    text: 'Te resolvemos las dudas sobre las coberturas de tus seguros',
    url: logo2
  },
  {
    text: 'Te avisamos 90 días antes del vencimiento de tu contrato',
    url: logo3
  },
  {
    text: 'Ofrecemos mejores ofertas a acorde a tus pólizas contratadas',
    url: logo4
  }
]

const InfoCard = () => {
  return (
    <ContentShadow id='info-card' className={'relative min-h-[472px] max-w-[1200px] mx-auto flex-wrap lg:flex-nowrap items-center justify-center p-8  flex'}>
      <div className="absolute left-0 top-0 max-w-[90%] w-[400px] h-[400px] z-10">
        <img src={BackgroundImage} />
      </div>
        <div className=" flex-wrap justify-center gap-5 w-[900px] flex relative z-20">
           {INFO_CARDS.map((item, index) => (
             <MiniCardLogo key={index} url={item.url} text={item.text} />
           ))}
        </div>

      <div className="justify-center lg:justify-start flex lg:px-8">
      <div className='flex flex-col justify-between gap-4  lg:w-6/12 py-4'>
          <Paragraphs >Todo son ventajas</Paragraphs>
          <Paragraphs className="max-w-[500px]" weight="bold" size="7xl">
            Razones para usar totsafe
          </Paragraphs>
            <Paragraphs>
              Nuestra experiencia nos ha dado la oportunidad de buscar las
              mejores soluciones para el sector de las pólizas empresariales,
              por eso nuestras soluciones son únicas.
            </Paragraphs>
          {/* <Button uppercase size= 'medium' mode='orange' label='saber más'/> */}
        </div>
        <div className="6/12">
          <div className=" hidden lg:flex lg:absolute lg:-right-10 bottom-[0px]">
          <img src={ImageBack} width={400} height={400}/>
          </div>
        </div>
      </div>
    </ContentShadow>
  )
}

export default InfoCard
