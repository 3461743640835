import { useTranslation } from 'react-i18next'
import Paragraphs from '../Paragraphs/Paragraphs'
import { constantsMiniLogos } from './contants'

const MiniLogos = () => {
  const { t } = useTranslation()
  return (
        <div className="flex w-full  gap-4 flex-wrap ">
          {constantsMiniLogos?.map((logo, index) => (
            <div key={index} className='flex flex-col items-center'>
             <img width={50} height={50} src={logo?.icon} alt={logo.name} />
             <Paragraphs className='text-white' size= 'xs'>{logo.name}</Paragraphs>
            </div>
          ))}
           <div className='flex flex-col items-center'>
           <div className='w-[40px] flex items-center justify-center h-[40px] rounded-lg mt-[4px] bg-gray-300'>
            <Paragraphs className={'text-center'} size={'3xl'}>...</Paragraphs>
          </div>
             <Paragraphs className='text-white mt-1' size= 'xs'>{t('Otros')}</Paragraphs>
            </div>

        </div>
  )
}

export default MiniLogos
