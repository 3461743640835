/* eslint-disable no-undef */
export const BASE_URL = 'https://backoffice.totsafe.es/'
export const API = 'api/'
export const ENDPOINT = {
  LOGIN: 'login',
  PROFILE: 'profile',
  INSURANCES: 'insurances',
  INSURANCE_TYPE: 'insurancetype',
  INSURERS: 'insurers',
  STORAGE: 'storage/',
  FAQS: 'faqs',
  RATING: 'rating',
  USERS: 'users',
  CONTACT: 'guest-contacts',
  RECOVER: 'recovery',
  READ: 'read'
}
