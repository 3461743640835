import styled from '@emotion/styled'
import BackgroundImage from '../../../infrastructure/assets/static/images/image_background_mobile.png'

export const ContainerBannerHome = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background-image: url(${BackgroundImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;
width: 100%;
height: 605px;
color: white;
padding-left: 350px;
font-size: 20px;

@media (max-width: 1095px){
    padding-left: 0px;
    align-items: center;
    background-position:right ;
}

`
