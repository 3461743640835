import Paragraphs from '../../Atoms/Paragraphs'
import GirlImagePig from '../../../infrastructure/assets/static/images/girl_pig.png'
import DonutChart from '../DonutChart'
import DonutListChart from '../DonutListChart'
import ContentShadow from '../../Atoms/ContentShadow'
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

const BannerHomeExpense = () => {
  const data = [
    { name: 'Terceros', value: 150 },
    { name: 'Robo', value: 100 },
    { name: 'Accidentes', value: 150 },
    { name: 'Salud', value: 100 }
  ]
  return (
    <ContentShadow id='banner-home-expense' className={'relative max-w-[1200px] mx-auto flex justify-center md:justify-start !p-0'}>
      <div className=" flex flex-col items-center lg:w-8/12 px-16 py-10 ">
        <div className='lg:max-w-[60%]'>
        <Paragraphs
          className="text-primary mb-8"
          size="7xl"
          weight="bold"

        >
          Controla los pagos en seguros
        </Paragraphs>
        <Paragraphs className="pr-3 text-primary  " >
          Desde el{' '}
          <span className="color-primary font-bold">panel de usuario</span>{' '}
          revisa lo que pagas de seguros
        </Paragraphs>
        </div>
        <div className="flex flex-wrap items-center justify-center  gap-5 mt-5">
          <DonutChart data={data} COLORS={COLORS} />
          <DonutListChart data={data} COLORS={COLORS} className='flex items-center gap-32' />
        </div>
      </div>
      <div className="w-4/12 bg-primary hidden md:flex rounded-r-xl">
        <div className=" absolute bottom-0 right-0 flex max-w-[350px] max-h-[520px]">
          <img src={GirlImagePig} alt="ahorro" className='' />
        </div>
      </div>
    </ContentShadow>
  )
}

export default BannerHomeExpense
