import Paragraphs from '../../Atoms/Paragraphs'
import { PieChart, Pie, Cell } from 'recharts'
import { InversionText } from './style'
import PropTypes from 'prop-types'

const DonutChart = ({ data, COLORS }) => {
  const sum = data?.reduce((acc, cur) => acc + cur.value, 0)
  return (
    <div className="relative w-56">
      <InversionText>
        <Paragraphs className="text-slate-400" size="sm" weight="normal">
        GASTO TOTAL ANUAL
        </Paragraphs>
        <Paragraphs weight="bold">
          {sum}€
        </Paragraphs>
      </InversionText>
      <PieChart width={220} height={220}>
        <Pie
          data={data}
          cx={100}
          cy={100}
          innerRadius={80}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          displayName="hello"
        >
          {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}

export default DonutChart

DonutChart.propTypes = {
  data: PropTypes.array.isRequired,
  COLORS: PropTypes.array.isRequired
}
