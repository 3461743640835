import React from 'react'
import PropTypes from 'prop-types'

const ContentShadow = ({ id, children, className }) => {
  return (
    <div id={`content-shadow-${id}`} className={` shadow-lg rounded-xl bg-white ${className}`}>
        {children}
    </div>
  )
}

ContentShadow.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
}

export default ContentShadow
