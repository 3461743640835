import { FaUserAlt, FaSignOutAlt } from 'react-icons/fa'
import { GrDocument } from 'react-icons/gr'
import { MdOutlineDashboard, MdFeedback } from 'react-icons/md'

export const navigationAccount = [
  { id: 1, name: 'Panel de control', icon: <MdOutlineDashboard />, url: '/mi-cuenta/dashboard' },
  { id: 2, name: 'Mis Datos', icon: <FaUserAlt />, url: '/mi-cuenta/datos' },
  { id: 3, name: 'Pólizas', icon: <GrDocument />, url: '/mi-cuenta/polizas' },
  { id: 4, name: 'Danos tu opinión', icon: <MdFeedback />, url: '/mi-cuenta/valoracion' },
  { id: 5, name: 'Cerrar sesión', icon: <FaSignOutAlt />, url: '/' }
]
