/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { GrDocumentText } from 'react-icons/gr'
import { FaEye } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { BASE_URL, ENDPOINT } from '../../../infrastructure/api/configuration'
import { differenceInDays, format } from 'date-fns'
import Paragraphs from '../../Atoms/Paragraphs'

const InsureDetail = ({ insure, showDetailInsurance }) => {
  const { expiration_date, id } = insure
  const [expired, setExpired] = useState('')

  useEffect(() => {
    const expirationDate = new Date(expiration_date)
    const unixTimestamp = Math.floor(expirationDate.getTime() / 1000)
    const now = Math.floor(new Date().getTime() / 1000)
    if (unixTimestamp < now) {
      setExpired('Caducada')
    } else {
      setExpired('Activa')
    }
  }, [expiration_date])

  const downloadfile = (file) => {
    const url = `${BASE_URL}${ENDPOINT.STORAGE}${file.file}`
    window.open(url, '_blank')
  }

  const now = new Date()
  const end = new Date(expiration_date)
  // const diference = differenceInMonths(end, now)
  const diferenceInDays = differenceInDays(end, now)
  return (
    <tr id={`insure-${id}`} key={insure.id} className="text-primary transition">
      <td className="text-xs pl-5 font-medium py-2 text-left">
        {insure?.expiration_date &&
          format(new Date(insure?.expiration_date), 'dd-MM-yyyy')}
      </td>
      <td className="text-sm font-medium px-2 text-left">
        {insure.insurance_number}
      </td>
      <td className="text-xs font-medium  px-2 text-left">{insure.cost}€</td>
      <td className="text-xs font-medium px-2 text-left">{expired}</td>
      <td className="text-xs font-medium px-2 text-left">
        {diferenceInDays < 90 && diferenceInDays > 0 && (
          <div className="bg-red-400 rounded-[5px] px-2 py-1 mt-1 text-white text-center">
            <Paragraphs size='xxs' weight={'bold'}>{`Vence en ${diferenceInDays} dias`}</Paragraphs>
          </div>
        )}
      </td>
      <td className="text-xs font-medium  px-2 text-left">
      {insure.custom_message && insure.custom_message_read !== 1 && (
        <div
          id='custom-message-insure'
          className="bg-red-50 rounded-[5px] relative px-2 mt-1 py-1  text-red-700 max-w-max"
          role="alert"
        >
          <Paragraphs size='xxs' className={'leading-[15px]'} weight={'bold'}>{insure?.custom_message}</Paragraphs>
        </div>
      )}
      </td>

      <td className="text-md font-medium flex  justify-end px-2 text-left">
        <div className="flex items-center mt-[5px] gap-2">
          {insure?.insurances_file?.map((file) => (
            <GrDocumentText
              onClick={() => downloadfile(file)}
              cursor="pointer"
              key={file.id}
            />
          ))}
          <Link id="eye-poliza" to={`/mi-cuenta/polizas/${id}`}>
            <FaEye cursor="pointer" />
          </Link>
        </div>
      </td>
    </tr>
  )
}

export default InsureDetail

InsureDetail.propTypes = {
  insure: PropTypes.object,
  showDetailInsurance: PropTypes.func
}
