import { FiMenu } from 'react-icons/fi'
import { GrClose } from 'react-icons/gr'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import Link from '../../../Atoms/Link'
import ButtonAcceso from '../../../Atoms/ButtonAcceso/ButtonAcceso'
import { Link as NextLink } from 'react-router-dom'
import { StyledModalOverlay } from '../../Modal/Modal'
import LOGO_HEADER from '../../../../infrastructure/assets/static/logosHeader/logo_brand.png'

const HeaderMobile = ({ onClickMenu, isOpen, setIsOpen, links }) => {
  const ref = useRef(null)

  const detectOutsideClick = (event) => {
    if (ref.current.contains(event.target)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', detectOutsideClick)
      return () => {
        document.removeEventListener('mousedown', detectOutsideClick)
      }
    }
  }, [isOpen])

  return (

    <div className='bg-white md:hidden w-full z-40  fixed top-0 min-h-[70px] '>
    <div onClick={onClickMenu} className=' text-5xl z-50 flex md:hidden fixed left-5 top-5'>
      {isOpen ? <GrClose /> : <FiMenu />}
    </div>
    {isOpen &&
     <StyledModalOverlay>
    <div ref={ref} className={`h-screen bg-white z-40 w-[50%] fixed flex flex-col justify-center gap-10 left-0 ${isOpen ? 'header-animation' : 'header-animation-back'}`}>
        <div id='logo' className='px-6'>
          <NextLink to={'/'}>
            <a>
              <img width={150} height={45} src={LOGO_HEADER} alt="totsafe" />
            </a>
          </NextLink>
        </div>
      <div className='flex flex-col gap-10'>

        {links.map((item) => (
          <div onClick={() => setIsOpen(false)} key={item.id}>
            <Link transform='uppercase' className={'px-6 flex items-center '} {...item} />
          </div>
        ))}

        <div onClick={() => setIsOpen(false)} className=''>
            <ButtonAcceso title="Acceso cliente" />
        </div>

      </div>
    </div>
    </StyledModalOverlay>
    }

 </div>

  )
}

export default HeaderMobile

HeaderMobile.propTypes = {
  onClickMenu: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  links: PropTypes.array
}
