import MiniLogos from '../../Atoms/MiniLogos'
import IconsStore from '../../Atoms/IconsStore'
import { ContainerBannerHome } from './style'
import Paragraphs from '../../Atoms/Paragraphs'
import djEventImg from '../../../../src/infrastructure/assets/static/images/image_background_header.png'
// import djEventImg from '../../../infrastructure/assets/static/images/image_background_header.png'

const BannerHeaderHome = () => {
  return (
    <ContainerBannerHome image={djEventImg}>
      <div className="w-[600px] max-w-[90%] w-md flex flex-col gap-7 sm:mx-auto md:mx-auto pl-8 lg:ml-44 py-24">
        <Paragraphs className="text-amber-400">
          La gestión y control de tus
          <br />
          pólizas en tu empresa, está con
        </Paragraphs>
        <Paragraphs className="text-white" size="9xl" weight="semibold">
          totsafe
        </Paragraphs>
        <Paragraphs className="text-white">
          {' '}
          Nos especializamos en todo tipo de seguros <br />
          gracias a nuestros especialistas.
        </Paragraphs>
        <MiniLogos />
        <IconsStore />
      </div>
    </ContainerBannerHome>
  )
}

export default BannerHeaderHome
