import Layout from '../../../components/Layout'
import LayoutAccount from '../../../components/Molecules/MyAccount/Layout/Layout'
import PolizaDetail from '../../../components/Molecules/PolizaDetail'

const DetailPoliza = () => {
  return (
    <LayoutAccount>
       <PolizaDetail/>
    </LayoutAccount>

  )
}

export default DetailPoliza
DetailPoliza.getLayout = function getLayout (page) {
  return <Layout pageTitle={'Pólizas'}>{page}</Layout>
}
