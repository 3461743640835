import Paragraphs from '../../Atoms/Paragraphs'
import PropTypes from 'prop-types'

const InfoText = ({ label, title, description, brDescription, className }) => {
  return (
        <div className={`flex flex-col ${className}`}>
            <Paragraphs className='mb-4'>{label}</Paragraphs>
            <Paragraphs className='mb-6' weight='bold' size= '7xl'>{title}</Paragraphs>
            <Paragraphs>
                {description}
                <br/>
                {brDescription && <Paragraphs className='mt-5'>{brDescription}</Paragraphs>}
            </Paragraphs>
        </div>
  )
}

export default InfoText

InfoText.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  brDescription: PropTypes.string
}
