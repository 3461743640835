import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'

const Label = ({ htmlFor, label, required, className, widthLabel }) => {
  return (
    <label htmlFor={htmlFor} className={`flex text-sm font-medium ${widthLabel} text-gray-700 mb-3  ${className}`}>
    <Paragraphs className={'flex'} size='sm'>{label}</Paragraphs>
    {required && <Paragraphs className={'text-red-400'}>*</Paragraphs>}
  </label>
  )
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  widthLabel: PropTypes.string
}

export default Label
