import React from 'react'
import PropTypes from 'prop-types'
import WrapperInput from '../WrapperInput'

const TextArea = ({
  placeholder,
  id,
  name,
  autoComplete,
  label,
  disabled,
  autoFocus,
  labelClassName,
  onBlur,
  onChange,
  register,
  required,
  error,
  description,
  type,
  value,
  onFocus
}) => {
  return (
    <WrapperInput
      height='auto'
     label={label}
      required={required}
      error={error}
      description={description}
      name={name}
      id={id}
      labelClassName={labelClassName}>
      <textarea
        onChange={onChange}
        rows={5}
        name={name}
        required={require}
        id={id}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className=" focus:ring-indigo-500 p-4 focus:border-indigo-500 block w-full h-full shadow-sm sm:text-sm"
        {...register}
      />
      {/* {error && <div className="text-red-500 text-[12px] mt-1">{error}</div>} */}

    </WrapperInput>
  )
}

TextArea.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  register: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func

}

TextArea.defaultProps = {
  register: {}
}

export default TextArea
