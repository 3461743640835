import React from 'react'
import PropTypes from 'prop-types'
import Paragraphs from '../../Atoms/Paragraphs/Paragraphs'
import styled from '@emotion/styled'
import BackgroundImage from '../../../infrastructure/assets/static/images/header_page_title.png'

const Container = styled.div`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: 20px;
  height: 180px;
  min-height: 100px;
  @media (max-width: 768px) {
    height: 100px;
    max-height: 100px;
  }
`

const PageTitle = ({ title }) => {
  return (
    <Container>
    <div className="absolute d-flex left-0 bottom-0 w-full  z-20 overflow-hidden">
        <div className="container mx-auto  flex items-end  overflow-hidden">
          <h1>
          <Paragraphs
            className={
              'text-7xl xl:!text-7xl text-white sm:-bottom-[12px] md:-bottom-[8px] lg:-bottom-[10px] xl:-bottom-[18px] -bottom-[10px]  tracking-[5px] relative'
            }
            transform="uppercase"
            weight="bold"
          >
            {title}
          </Paragraphs>
          </h1>
        </div>
      </div>
    </Container>
  )
}

PageTitle.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string
}

export default PageTitle
