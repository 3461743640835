import { useInsure } from '../../../infrastructure/hooks/useInsure'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ListPolizas from '../ListPolizas'

const Polizas = () => {
  const { getInsuresUser, insures } = useInsure()
  const [showModal, setShowModal] = useState(false)
  // const [detailPoliza, setDetailPoliza] = useState({})
  // const [showDetailPoliza, setShowDetailPoliza] = useState(false)
  // const [estado, setEstado] = useState(null)

  useEffect(() => {
    getInsuresUser(true) // trae todas las polizas esten caducadas o no
  }, [])

  const onClickAdd = () => {
    setShowModal(true)
  }

  const onCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div className="sm:pl-5 min-h-[500px] mx-auto w-full gap-5 flex flex-col">
        <ListPolizas showModal={showModal} onClickAdd={onClickAdd} onCloseModal={onCloseModal} insures={insures} />
    </div>
  )
}

export default Polizas

Polizas.propTypes = {
  insures: PropTypes.array
}
