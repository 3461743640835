export const INPUTS_CONTACT = [
  {
    id: 'name',
    placeholder: 'Introduzca su nombre',
    type: 'text',
    label: 'Nombre',
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'w-6/12'
  },
  {
    id: 'company',
    placeholder: 'Introduzca su empresa',
    type: 'text',
    label: 'Empresa',
    width: 'w-6/12'

  },
  {
    id: 'email',
    placeholder: 'Introduzca su correo electrónico',
    type: 'email',
    label: 'Correo electrónico',
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'w-6/12'

  },
  {
    id: 'phone',
    placeholder: 'Introduzca su número de teléfono',
    type: 'phone',
    label: 'Teléfono',
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'w-6/12'
  },
  {
    id: 'subject',
    placeholder: 'seleccione asunto',
    type: 'text',
    label: 'Asunto',
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'sm:w-full w-6/12'
  },
  {
    id: 'comment',
    placeholder: 'Introduzca su mensaje',
    type: 'textarea',
    label: 'Mensaje',
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'sm:w-full w-6/12'
  }

]
