import Button from '../../Atoms/Button'
import Paragraphs from '../../Atoms/Paragraphs'
import AddPoliza from '../AddPoliza'
import InsureDetail from '../InsureDetail'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import { BsPlusCircle } from 'react-icons/bs'

const ListPolizas = ({ onClickAdd, showModal, onCloseModal, showDetailInsurance, insures }) => {
  return (
    <>
    <div className='w-[250px] max-w-[90%]'>
      <Button
      id='btn-add-poliza'
        onClick={onClickAdd}
        type={'submit'}
        label="Añadir nueva póliza"
        uppercase
        iconRight={<BsPlusCircle/>}
        mode="orange"
        size="full"
      />
      </div>
      <Modal showModal={showModal}>
        <AddPoliza onCloseModal={onCloseModal} />
      </Modal>
      <div className="w-full flex flex-col gap-5">
        <Paragraphs className="text-primary" weight="semibold" size="xl">
          Historial de Pólizas
        </Paragraphs>
        <div className="relative overflow-x-auto shadow-md ">
          <div className="flex flex-col">
            <div className="overflow-x-auto ">
              <div className="py-2 inline-block min-w-[600px] sm:min-w-full sm:px-6 lg:px-3">
                <div className="overflow-hidden">
                  <table className="min-w-full">
                    <thead className="border-b bg-background  text-primary uppercase">
                      <tr className=" gap-10 text-primary">
                        <th
                          scope="col"
                          className="text-sm pl-5 font-semibold py-2 w-[220px]  text-left"
                        >
                          Fecha de vencimiento
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold  px-2 w-[120px] text-left"
                        >
                          nº póliza
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold px-2 w-[120px]  text-left"
                        >
                          importe
                        </th>

                        <th
                          scope="col"
                          className="text-sm font-semibold  px-2  text-left"
                        >
                          estado
                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold w-[120px] px-2 text-left"
                        >

                        </th>
                        <th
                          scope="col"
                          className="text-sm font-semibold  px-2 text-left"
                        >

                        </th>
                        <th
                          scope="col"
                          className="font-semibold w-[120px] text-xs capitalize"
                        >
                          Documentos
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {insures?.map((insure) => insure?.insurance_number && (
                        <InsureDetail

                          showDetailInsurance={showDetailInsurance}
                          key={insure.id}
                          insure={insure}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ListPolizas

ListPolizas.propTypes = {
  onClickAdd: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  showDetailInsurance: PropTypes.bool.isRequired,
  insures: PropTypes.array.isRequired
}
