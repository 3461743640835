import React from 'react'
import PropTypes from 'prop-types'
import WrapperInput from '../WrapperInput'

const Checkbox = ({
  placeholder,
  id,
  name,
  autoComplete,
  label,
  disabled,
  autoFocus,
  labelClassName,
  onBlur,
  onChange,
  register,
  required,
  error,
  description,
  type,
  value,
  onFocus,
  size,
  checked,
  flexDirection,
  style

}) => {
  return (
    <WrapperInput
      label={label}
      style={style}
      flexDirection={flexDirection}
      required={required}
      error={error}
      description={description}
      name={name}
      id={id}
      type={type}
      height='auto'
      labelClassName={labelClassName}>
      <input
        checked={checked}
        onChange={onChange}
        name={name}
        type={type}
        required={require}
        id={id}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={`focus:ring-indigo-500 px-4 focus:border-indigo-500  w-[${size}px] h-[${size}px] shadow-sm sm:text-sm`}
      />

    </WrapperInput>
  )
}

Checkbox.propTypes = {
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  labelClassName: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  register: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  size: PropTypes.string,
  checked: PropTypes.number,
  flexDirection: PropTypes.string,
  style: PropTypes.object

}

Checkbox.defaultProps = {
  type: 'checkbox'
}

export default Checkbox
