export const FOOTER_LINKS = [
  {
    id: 1,
    name: 'Empresa',
    text: 'Somos una empresa que ha desarrollado una plataforma digital llamada “TOTSAFE” con la finalidad de concentrar las pólizas de seguros y gestionarlos eficientemente.'
  },
  {
    id: 2,
    name: 'Contáctanos',
    text: 'Contáctanos a través de nuestro correo  info@totsafe.es'
  },
  {
    id: 3,
    name: 'Políticas',
    links: [
      {
        id: 1,
        name: 'Aviso legal',
        url: '/aviso-legal'
      },
      {
        id: 2,
        name: 'Política de privacidad',
        url: '/politica-privacidad'
      },
      {
        id: 3,
        name: 'Política de cookies',
        url: '/politica-cookies'
      }
    ]
  }
]
