import Paragraphs from '../../Atoms/Paragraphs'
import PropTypes from 'prop-types'

const DonutListChart = ({ data, COLORS, className }) => {
  return (
    <div className={`${className}`}>
      <ul className="flex flex-col gap-4">
        {data?.map((entry, index) => (
          <li key={index} className="flex gap-5 items-center jus">
            <div
              style={{ backgroundColor: COLORS[index % COLORS.length] }}
              className="w-2 h-2"
            ></div>
            <Paragraphs size="sm">{entry.name}</Paragraphs>
          </li>
        ))}
      </ul>
      <ul className="flex flex-col gap-4">
        {data?.map((entry, index) => (
          <Paragraphs key={index} size="sm">
            {entry.value}€
          </Paragraphs>
        ))}
      </ul>
    </div>
  )
}

export default DonutListChart

DonutListChart.propTypes = {
  data: PropTypes.array.isRequired,
  COLORS: PropTypes.array.isRequired,
  className: PropTypes.string
}
