import Paragraphs from '../../Atoms/Paragraphs'
import TestimonalCard from '../../Atoms/TestimonialCard'
import PropTypes from 'prop-types'
import { CircleBack, CircleBackRed, ContainerTestimonialsBanner } from './style'

const TestimonialBanner = ({ testimonials }) => {
  return (
        <ContainerTestimonialsBanner>
            <div className='flex flex-col items-center xl:items-start  gap-10 xl:z-10'>
              <div>
                <Paragraphs className='primary mb-8'>Testimonios</Paragraphs>
                <Paragraphs className='primary' weight="semibold" size="7xl">Nuestros clientes opinan</Paragraphs>
                </div>
                <div className=' justify-center flex-wrap flex gap-4'>
                    {testimonials.map((testimonial, index) => (
                        <TestimonalCard key={index} testimonial={testimonial} />
                    ))}
                </div>
            </div>
            <CircleBack></CircleBack>
            <CircleBackRed></CircleBackRed>
        </ContainerTestimonialsBanner>
  )
}

export default TestimonialBanner

TestimonialBanner.propTypes = {
  testimonials: PropTypes.array.isRequired
}
