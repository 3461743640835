import { useForm } from 'react-hook-form'
import Button from '../../components/Atoms/Button/Button'
import Checkbox from '../../components/Atoms/Checkbox'
import Paragraphs from '../../components/Atoms/Paragraphs'
import TextArea from '../../components/Atoms/TextArea'
import TextInput from '../../components/Atoms/TextInput'
import Title from '../../components/Atoms/Title'
import Layout from '../../components/Layout'
import Page from '../../components/Page'
import { ENDPOINT } from '../../infrastructure/api/configuration'
import { INPUTS_CONTACT } from '../../infrastructure/constans/inputs/inputs-contact'
import { useMutation } from '../../infrastructure/hooks/useMutation'
import { useUser } from '../../infrastructure/hooks/useUser'
import { toast } from 'react-toastify'
import { useState } from 'react'
import Loading from '../../components/Molecules/Loading'

const Contacto = () => {
  const { register, handleSubmit, setError, formState: { errors } } = useForm()
  const [fetchContact, { loading }] = useMutation(ENDPOINT.CONTACT)
  const [accept, setAccept] = useState(false)
  const { user } = useUser()

  const onSubmit = async values => {
    if (!accept) {
      toast.error('Debes aceptar los términos y condiciones')
      return
    }
    if (user?.id) {
      values.user_id = user.id
    }

    const { success, error } = await fetchContact({
      method: 'post',
      variables: values
    })
    if (success) {
      return toast.success('Mensaje enviado correctamente')
    }
    if (error) {
      const validations = Object.keys(error).flat()
      if (validations) {
        validations.forEach(input => {
          setError(input, { type: 'validation', message: error[input][0] })
        })
      }
    }
  }

  const onChangeCheckbox = (e) => {
    setAccept(e.target.checked)
  }

  return (
    <Page pageTitle={'Contacto'}>
    <div id="contacto" className="py-8 w-[1200px] max-w-[90%] container mx-auto">
      <div className={`contacto-form-container relative  bg-white p-5 lg:p-20 ${loading ? 'opacity-50' : null} `}>
      {loading && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
        <div className="contacto-form-title text-center">
          <Title title="Formulario de contacto"/>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="contacto-form mt-12">

          <div className="contacto-form-inputs  items-center justify-center flex flex-wrap">

            {
              INPUTS_CONTACT.map(input => (<div key={input.id} className={`${input.width} min-w-[300px]  p-2`}>
                {input.type === 'textarea' ? <TextArea error={errors?.[input.id]?.message} {...input} register={register(input.id)} /> : <TextInput error={errors?.[input.id]?.message} register={register(input.id)} {...input} />}
                </div>))
            }
          </div>
          <div>
          <div className='flex pl-2 py-2'>
            <Checkbox id='xxx' name='xxx' onChange={onChangeCheckbox}/>
            <Paragraphs>He leído y acepto la Política de Privacidad y el clausulado legal</Paragraphs>
          </div>
          <Paragraphs size='xxs' className='text-gray-400 pb-5'>En el cumplimiento de lo establecido en la normativa vigente de Protección de Datos de Carácter Personal, le comunicamos que los datos recogidos en el presente formulario, quedarán incorporados y serán tratados en el fichero denominado Usuarios Web bajo la responsabilidad de NORMATIZA CONSULTING S.L. con el fin de poder prestar sus servicios, así como para mantenerlo informado sobre cuestiones relativas a la actividad de la empresa. Le informamos que los datos que nos facilite serán tratados de forma leal, transparente, adecuada, pertinente, limitada, exacta, actualizada, y no se comunicarán o ceden a terceros, exceptuando que esta comunicación sea necesaria o pueda suponer una mejora para la finalidad de los mismos, siempre acorde a la normativa vigente en materia de protección de datos, Reglamento general de protección de datos, en el supuesto de que se produzcan cambios en algunos de los datos facilitados, con la finalidad de mantener nuestro fichero actualizado, le rogamos nos lo comunique por escrito. Le informamos de la posibilidad que tienen de ejercer los derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad de sus datos de carácter personal de forma presencial en las oficinas de PLATAFORMA TOT SAFE, SL acompañando copia DNI, o bien mediante correo postal a PASSEIG DE FABRA I PUIG, 263, 08031 BARCELONA, o correo electrónico info@totsafe.es</Paragraphs>
          </div>
          <div className="contacto-form-submit flex items-center justify-center">
            <Button id='button_enviar' type= 'submit' label='Enviar' uppercase mode='orange' size='medium'/>
          </div>
        </form>
      </div>
    </div>
    </Page>
  )
}

Contacto.propTypes = {}

Contacto.getLayout = function getLayout (page) {
  return <Layout pageTitle="Contacto">{page}</Layout>
}

export default Contacto
