import styled from '@emotion/styled'
import UmbrellaImage from '../../../infrastructure/assets/static/images/umbrella.png'

export const ContainerBackground = styled.div`
  background-image: url(${UmbrellaImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 35%;
  height:100%;
`
