/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import LOGO_HEADER from '../../../infrastructure/assets/static/logosHeader/logo_brand.png'
import Link from '../../Atoms/Link'
import { Link as NextLink } from 'react-router-dom'
import { useState } from 'react'
import HeaderMobile from './HeaderMobile'
import ButtonAcceso from '../../Atoms/ButtonAcceso/ButtonAcceso'

const Header = ({ links }) => {
  // no cambiar el alto del header
  const [isOpen, setIsOpen] = useState(false)
  const onClickMenu = () => {
    setIsOpen(!isOpen)
  }

  return (

    <>
     <HeaderMobile links={links} setIsOpen={setIsOpen} isOpen={isOpen} onClickMenu={onClickMenu} />
     <header id="header" className={'sm:z-0  top-0 hidden  sm:h-[70px] sm:w-full min-h-[70px] md:flex  bg-white  sm:flex-row '}>
     <div className="   container h-full hidden   mx-auto md:flex sm:flex-row  sm:justify-between items-center sm:h-full">
        <div id='logo' className='sm:block hidden'>
          <NextLink to={'/'}>
            <a>
              <img width={150} height={45} src={LOGO_HEADER} alt="totsafe" />
            </a>
          </NextLink>
        </div>
        <div className="image-logo md:flex justify-center w-full   sm:justify-end items-center h-full ">
          <div className={'header-links-menu md:h-full max-w-[100%]   sm:flex sm:flex-row flex sm:gap-0 flex-col gap-16  '}>
            {links.map((item) => {
              return (
                <Link transform='uppercase' className={'px-6 flex items-center '} key={item.id} {...item} />
              )
            })}
            <div className='flex items-center '>
            <ButtonAcceso title="Acceso cliente" />
            </div>
          </div>
        </div>
      </div>
    </header>
    </>

  )
}
Header.propTypes = {
  links: PropTypes.array
}

export default Header
