import NavigationAccount from '../../NavigationAccount/NavigationAccount'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionSetLogout } from '../../../../infrastructure/redux/actions/user'
import { useUser } from '../../../../infrastructure/hooks/useUser'
import { useNavigate, useLocation } from 'react-router-dom'
import Page from '../../../Page'

const LayoutAccount = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()

  const routeAccount = location.pathname.split('/')[2]

  const { user } = useUser()

  const onClickNavigation = (id) => {
    if (id === 5) {
      dispatch(actionSetLogout())
      // router.push('/')
    }
  }

  useEffect(() => {
    if (!user) {
      navigate('/')
    }
  }, [user])

  return (
    <Page pageTitle={routeAccount === 'valoracion' ? 'valoración' : routeAccount}>
    <div id='mi-cuenta' className='navigation-account container mx-auto'>
       {user
         ? <div className="w-[1300px]  gap-5 lg:gap-0 lg:flex-row flex flex-col max-w-[90%]  mt-10 mx-auto border-[1px] border-slate-200  relative p-5 bg-white">
            <NavigationAccount onClickNavigation={onClickNavigation} />
            {children}
        </div>
         : null }
    </div>
    </Page>
  )
}

export default LayoutAccount

LayoutAccount.propTypes = {
  children: PropTypes.node
}
