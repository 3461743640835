// import ButtonMore from '../../Atoms/ButtonMore/ButtonMore'
import Paragraphs from '../../Atoms/Paragraphs'
import { ContainerBannerHome } from './style'
// import Button from '../../Atoms/Button/Button'

const BannerGestionHome = () => {
  return (
        <ContainerBannerHome>
            <div className='flex flex-col gap-10 max-w-[90%]  w-[500px] 2xl:w-2/5 '>
                <Paragraphs weight="bold" size="7xl">Gestiona todo de manera sencilla</Paragraphs>
                <Paragraphs color= 'secondary'>Nuestros usuarios son la base fundamental para el desarrollo de nuestro trabajo</Paragraphs>
                <ul className='flex flex-col gap-3'>
                    <Paragraphs >✅ Compromiso</Paragraphs>
                    <Paragraphs >✅ Confianza</Paragraphs>
                    <Paragraphs >✅ Precios justos</Paragraphs>
                </ul>
                {/* <Button uppercase size= 'medium' mode='orange' label='saber más' /> */}
            </div>

        </ContainerBannerHome>
  )
}

export default BannerGestionHome
