import { useForm } from 'react-hook-form'
import { INPUTS_REGISTER } from '../../../infrastructure/constans/inputs/inputs-register'
import Button from '../../Atoms/Button'
import Checkbox from '../../Atoms/Checkbox'
import Paragraphs from '../../Atoms/Paragraphs'
import TextArea from '../../Atoms/TextArea'
import TextInput from '../../Atoms/TextInput'
import Title from '../../Atoms/Title'
import { useDispatch } from 'react-redux'
import { actionSetLogout, actionSetProfile } from '../../../infrastructure/redux/actions/user'
import { useUser } from '../../../infrastructure/hooks/useUser'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Modal from '../../Molecules/Modal'
import Loading from '../Loading'

const INPUTS_PASSWORD = [
  {
    id: 9,
    label: 'Contraseña Actual',
    name: 'password',
    secureTextEntry: true,
    type: 'password'
  },
  {
    id: 10,
    label: 'Nueva contraseña',
    name: 'password',
    secureTextEntry: true,
    type: 'password',
    description: 'Mínimo 8 caracteres y debe contener, numero, letras y un caracter especial'
  }
]

const InfoAccount = () => {
  const [accept, setAccept] = useState(false)
  const [privacity, setPrivacity] = useState(false)
  // const [loading, setLoading] = useState(false)
  const { updateProfile, user, deleteUser, loading } = useUser()
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      ...user
    }
  })
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const onSubmit = async (values) => {
    window.scrollTo(0, 0)
    if (!accept) {
      toast.error('Debes aceptar los términos y condiciones y el campo de privacidad')
      return
    }
    if (!privacity) {
      toast.error('Debes aceptar el campo de privacidad')
      return
    }
    const { success, error, data } = await updateProfile(values)
    if (success) {
      dispatch(actionSetProfile(data?.user))
      toast.success('Actualización correcta')
    }
    if (error) {
      const validations = Object.keys(error).flat()
      if (validations) {
        validations.forEach(input => {
          setError(input, { type: 'validation', message: error[input][0] })
        })
      }
    }
  }
  const inputs = INPUTS_REGISTER.filter(input => {
    if (input.name === 'password') return null
    if (input.typeFor) {
      return input.typeFor === user?.user_type_id
    }
    return input
  })

  const onChangeCheckbox = (e) => {
    setAccept(e.target.checked)
  }
  const onChangeCheckboxPrivacity = (e) => {
    setPrivacity(e.target.checked)
  }

  const [openAlert, setOpenAlert] = useState(false)

  // const router = useRouter()

  const handleDelete = async () => {
    // eliminar cuenta
    const response = await deleteUser()

    if (response.success) {
      navigate('/')
      dispatch(actionSetLogout())
    }
  }

  const alertDelete = () => {
    setOpenAlert(true)
  }

  const handleClose = () => {
    setOpenAlert(false)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`gap-5 lg:gap-0 flex flex-col lg:flex-row w-full ${loading ? 'opacity-50' : null}`}>
       {loading && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
       <div onClick={alertDelete} className='absolute right-5 top-5 border-[1px] bg-red-400 border-alert cursor-pointer transition-all hover:bg-red-600 text-secondary text-sm px-5 py-1'>
         Dar de baja
      </div>
      <Modal onCloseModal={() => setOpenAlert(false)} showModal={openAlert} >
        <div className=' p-5 flex flex-col gap-5 '>
          <Paragraphs className='text-primary' size='md' weight='semibold'>¿Está seguro de eliminar la cuenta?</Paragraphs>
          <div className='flex justify-evenly'>
            <Button onClick={handleDelete} mode='orange' size='small' label='Ok'/>
            <Button onClick={handleClose} size='small' label='Cancelar'/>
          </div>
        </div>
      </Modal>
        <div className="w-full  max-w-[98%] flex pl-5 justify-center flex-col ">
          <div className=" mb-5">
            <Title title="Información de cuenta" />
          </div>
          <div className="w-full justify-center gap-3 flex flex-col border-b-[1px] border-slate-200 pb-5">

            {inputs.map((input) => (
              <div key={input.id} className="lg:w-[800px]  p-2">
                {input.type === 'textarea'
                  ? (
                  <TextArea {...input} register={register(input.name)} />
                    )
                  : (

                  <TextInput
                    flexDirection=""
                    className="max-w-[90%] md:items-center  flex flex-col sm:flex-row w-full gap-2 sm:gap-7"
                    register={register(input.name)}
                    {...input}
                    topEye={'top-3'}
                    error={errors?.[input.name]?.message}
                    hideLabel = {false}
                    widthLabel= 'w-[220px]'
                  />

                    )}
              </div>
            ))}
          </div>
          <div className='w-full justify-center mt-4 gap-2 flex flex-col'>
            {INPUTS_PASSWORD.map((input) => (
                <div key={input.id} className="lg:w-[800px]  p-2">
                <TextInput
                  key={input.id}
                  type={input.type}
                  flexDirection=""
                  className="max-w-[90%] md:items-center flex flex-col sm:flex-row w-full gap-2 sm:gap-7"
                  register={register(input.name)}
                  {...input}
                  error={errors?.[input.name]?.message}
                  widthLabel= 'w-[220px]'
                />
               </div>
            ))}

          </div>
          <div className='flex flex-col'>
          <div className='flex   mt-8'>
         <Checkbox
              id="privacidad"
              name="privacidad"
              onChange={onChangeCheckboxPrivacity}
            />
            <Paragraphs>
                Privacidad de los datos del cliente
                <br/>
                <span className='text-slate-500'>Los datos personales que proporciona son utilizados para satisfacer sus necesidades, procesar pedidos o permitirle el acceso a una información específica. Usted tiene el derecho de modificar y eliminar toda la información personal que se encuentra en la página “Mi Cuenta”.</span>
            </Paragraphs>
          </div>
          <div className='flex   mt-8'>
         <Checkbox
              id="condiciones"
              name="condiciones"
              onChange={onChangeCheckbox}
            />
            <Paragraphs>Acepto las condiciones generales y la política de confidencialidad</Paragraphs>
          </div>
          </div>
          <Paragraphs className='text-red-600 mt-5'>Campos Obligatorios *</Paragraphs>
          <div className="mt-5  flex items-center justify-center">
            <Button
              id = 'btn-datos'
              type={'submit'}
              label="Guardar"
              uppercase
              mode="orange"
              size="medium"
            />
          </div>
        </div>
        </form>
  )
}

export default InfoAccount
