import ProTypes from 'prop-types'
import Paragraphs from '../Paragraphs/Paragraphs'
import { ButtonAccesoStyled } from './style'
import { useUser } from '../../../infrastructure/hooks/useUser'
import { Link as NextLink, useNavigate } from 'react-router-dom'
import { FiUser } from 'react-icons/fi'

const ButtonAcceso = ({ title }) => {
  const { user } = useUser()

  const navigate = useNavigate()

  return (
    <>
      {user
        ? (
        <NextLink to={'/mi-cuenta/dashboard'}>
          <a className="flex px-6 h-full  items-center ">
            <Paragraphs
              transform="uppercase"
              weight="semibold"
              className=" flex  gap-2 items-center cursor-pointer text-primary  "
            >
              {user?.user_type_id === 1 ? user?.company : user?.name}
              <FiUser />
            </Paragraphs>
          </a>
        </NextLink>
          )
        : (
        <ButtonAccesoStyled
        id='button_acceso'
          onClick={() => navigate('login')}
          className="h-full w-[217px] max-w-[100%]  flex justify-center items-center cursor-pointer p-4"
        >
          <Paragraphs
            className={'text-white'}
            size="sm"
            transform={'uppercase'}
          >
            {title}
          </Paragraphs>
        </ButtonAccesoStyled>
          )}
    </>
  )
}

export default ButtonAcceso

ButtonAcceso.propTypes = {
  title: ProTypes.string
}
