import { useForm } from 'react-hook-form'
import { ENDPOINT } from '../../../infrastructure/api/configuration'
// import { useDispatch } from 'react-redux'
import { useMutation } from '../../../infrastructure/hooks/useMutation'
// import { actionSetToken } from '../../../infrastructure/redux/actions/user'
import { Container } from '../LoginModal/style'
import Paragraphs from '../../Atoms/Paragraphs'
import LogoHeader from '../../../infrastructure/assets/static/images/logo_blanco.png'
import TextInput from '../../Atoms/TextInput'
import Button from '../../Atoms/Button'
import Link from '../../Atoms/Link'
import Modal from '../Modal'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Loading from '../Loading'
// import { useNavigate } from 'react-router-dom'

const INPUT_EMAIL = {

  id: 7,
  label: 'Email',
  placeholder: 'Email',
  name: 'email',
  secureTextEntry: true,
  required: {
    message: 'El campo es obligatorio'
  },
  width: 'w-12/12',
  type: 'email'

}

const ResetPassword = () => {
  const [fetchRecover] = useMutation(ENDPOINT.RECOVER)
  const [showMessage, setShowMessage] = useState(false)
  const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()
  // const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm()

  const onSubmit = async (values) => {
    setLoading(true)
    const response = await fetchRecover({ variables: values, method: 'post' })
    if (response?.data?.success) {
      setLoading(false)
      setShowMessage(true)
      setValue('email', '')
    } else {
      setLoading(false)
      toast.error('No se ha encontrado ningun usuario con ese email')
    }
  }

  return (
        <Container>

        <Modal onCloseModal={() => setShowMessage(false)} showModal = {showMessage} >
            <div className='p-5'>
            <Paragraphs className='text-primary' size='md' weight='semibold'>
                Se ha enviado un correo para restablecer la contraseña
            </Paragraphs>
            </div>
        </Modal>

      <div className="mx-auto mb-5">
        <img className= 'bg-transparent' width={320} height={100} src={LogoHeader} />
      </div>
      <div
        id="modal_login"
        className={`mx-auto pt-8  bg-slate-100 w-[600px] max-w-[90%] flex flex-col justify-center items-center ${loading ? 'opacity-50' : null}`}
      >
         {loading && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
        <Paragraphs size="4xl" weight="semibold">
        ¡Acceso clientes!
        </Paragraphs>
        <Paragraphs className="max-w-[90%]">
          Recuperar contraseña
        </Paragraphs>
        <form onSubmit={handleSubmit(onSubmit)} className=" w-[80%] mt-8">
          <div className="login-form-inputs gap-8  w-full flex  flex-col">

              <div key={INPUT_EMAIL.id} className={`${INPUT_EMAIL.width}`}>

                  <TextInput className='flex flex-col gap-2' type={INPUT_EMAIL.type} error={errors?.[INPUT_EMAIL.name]?.message} register={register(INPUT_EMAIL.name)} {...INPUT_EMAIL} />

              </div>

          </div>
         <div className="contacto-form-submit mt-5 flex items-center justify-center">
            <Button
              // onClick={onSubmit}
              type={'submit'}
              label="Enviar"
              uppercase
              mode="orange"
              size="medium"
            />
          </div>
        </form>
        <div className="bg-primary py-8 w-full mt-8 text-center">
          <Link
            transform="uppercase"
            url="/registro"
            className="text-secondary border-b-4 border-white"
            name="¿Aun no estas registrado?"
          />
        </div>
      </div>
      <div className=" text-secondary mt-2 mx-auto max-w-[90%] w-[600px]">
        <Link url="/login" name="Volver" />
      </div>
    </Container>

  )
}

export default ResetPassword
