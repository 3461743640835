import LayoutAccount from '../../../components/Molecules/MyAccount/Layout/Layout'
import Layout from '../../../components/Layout'
import Polizas from '../../../components/Molecules/Polizas/Polizas'

const PolizasPage = () => {
  return (
        <LayoutAccount>
            <Polizas/>
        </LayoutAccount>
  )
}

PolizasPage.getLayout = function getLayout (page) {
  return <Layout pageTitle={'Pólizas'}>{page}</Layout>
}

export default PolizasPage
