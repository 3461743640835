import BannerApps from '../../components/Molecules/BannerApps'
import BannerAboutInfo from '../../components/Molecules/BannerAboutInfo/BannerAboutInfo'
import BannerAboutValores from '../../components/Molecules/BannerAboutValores/BannerAboutValores'
// import BannerAboutFilosofia from '../../components/Molecules/BannerAboutFilosofia'
import BannerMission from '../../components/Molecules/BannerMission/BannerMission'
import BannerAboutTeam from '../../components/Molecules/BannerAboutTeam'
import PartnersAbout from '../../components/Molecules/PartnersAbout'
// import Layout from '../../components/Layout'
import Page from '../../components/Page'

const About = () => {
  return (
    <Page pageTitle={'Sobre nosotros'}>
    <div id="about" className="flex pt-10 flex-col gap-10 md:gap-20">

      <div id="banner-1" className="  container mx-auto max-w-[80%]">
       <BannerAboutInfo/>
      </div>
      <div
        id="banner-2"
        className=" container mx-auto max-w-[90%] "
      >
        <BannerAboutValores/>
      </div>
      {/* <div
        id="banner-3"
        className=" container mx-auto max-w-[90%] "
      >
      <BannerAboutFilosofia/>
      </div> */}
        <div
        id="banner-4"
        className="container mx-auto max-w-[90%]  "
      >
      <BannerMission/>
      </div>
       <div
        id="nuestro-equipo"
        className="container mx-auto max-w-[90%]"
      >
      <BannerAboutTeam/>
      </div>
       <div
        id="partners"
        className="container mx-auto max-w-[90%]"
      >
        <PartnersAbout />
      </div>
       <div id="banner-apps" className="w-full">
         <BannerApps />
      </div>
    </div>
    </Page>
  )
}

/* About.getLayout = function getLayout (page) {
  return <Layout pageTitle='sobre nosotros'>{page}</Layout>
} */

export default About
