import Paragraphs from '../../Atoms/Paragraphs'
import { ContainerBackground } from './styles'

const LIST_VALORES = [
  {
    text: 'Asesorar a las empresa y a los autónomos sobre las coberturas de los seguros.'
  },
  {
    text: 'Unificar los seguros contratados en una app y web. Les avisamos de sus vencimientos 90 días antes de su vencimiento.'
  },
  {
    text: 'Ofertamos otras opciones si es pertinente, y sólo cobramos si les aportamos ahorro con las mismas garantías o mejores.'
  },
  {
    text: 'Les podemos gestionar nuevas pólizas a través de distintas corredurías o compañías de seguros.'
  },
  {
    text: 'Si tienes una controversia en la reclamación de un siniestro; te ofrecemos un perito externo y una asesoría jurídica.',
    list: {
      type: 'number',
      items: ['1. Primero lo analizamos y te damos una apreciación.', '2. Si vemos posibilidades de éxito te gestionamos el siniestro con peritos externos y cobramos 20% del porcentaje de éxito.']
    }
  }

]

const BannerAboutValores = () => {
  return (

       <div className='flex items-center justify-center mt-10 md:mt-0 flex-wrap gap-20 h-auto md:h-[600px]'>
        <div className='flex flex-col md:px-0 px-5 gap-8 md:gap-16'>
          <div className='flex flex-col justify-center gap-3'>
         <Paragraphs className={'text-primary '} weight='bold' size= '7xl'>
           ¿Qué ofrecemos?
         </Paragraphs>
         <div id='bg-line' className='w-[200px] h-[3px] bg-pink'></div>
         </div>
         <ul className='max-w-[450px] flex flex-col gap-8'>
            {LIST_VALORES.map((item, index) => (
              <>
              <li className='' key={index}>
                <div className='flex gap-2'>
                ✅
                <Paragraphs>{item.text}</Paragraphs>
                </div>
                {
                item.list && <ul className='pl-7'>
                  {item.list.items.map((item, index) => <li className='mt-2' key={index}><Paragraphs italic size='xs' >{item}</Paragraphs></li>)}
                </ul>
              }
              </li>

              </>
            ))}
         </ul>
        </div>
        <ContainerBackground></ContainerBackground>
       </div>

  )
}

export default BannerAboutValores
