// import { FooterContainer, CampText } from './style'
// import Paragraph from '../../Atoms/Paragraph/Paragraph'
// import ButtonTelf from '../../Atoms/ButtonTelf'
// import LogoFooter from '../../../infrastructure/assets/static/images/logo_footer.png'
// import logo from '../../../../src/infrastructure/assets/static/images/logo_footer.png'
import logo from '../../../infrastructure/assets/static/images/logo_footer.png'
import { FOOTER_LINKS } from '../../../infrastructure/constans/footer/links'
import Link from '../../Atoms/Link'
import Paragraphs from '../../Atoms/Paragraphs/Paragraphs'

// import Image from 'next/image'
const Footer = () => {
  return (
    <div className="bg-footer tw-w-full mt-20" id="footer">
      <div className="container mx-auto py-4">
        <div className="flex flex-wrap justify-center p-4">
          <img src={logo} width={208} height={60} alt="logo_footer" />
        </div>
        <div className="flex flex-wrap justify-center md:gap-20 py-8">
          {FOOTER_LINKS.map((i) => {
            return (
              <div key={i.id} className="w-full max-w-[300px] text-center  p-4">
                <Paragraphs
                  weight={'bold'}
                  className="text-center"
                  size="sm"
                  transform={'uppercase'}
                >
                  {i.name}
                </Paragraphs>
                <div className="mt-3 ">
                  {i?.links?.map((e) => {
                    return <Link size="xs" key={e.id} {...e} />
                  })}
                  {i.text && <Paragraphs size="xs">{i.text}</Paragraphs>}
                </div>
              </div>
            )
          })}
        </div>
        <div className="border-b border-t border-gray-300 border-solid py-8 text-center flex justify-center items-center flex-col">
          <Paragraphs size="xs">¿Necesitas ayuda?</Paragraphs>
          <div className="bg-white rounded-full w-[200px] p-4 mt-4 cursor-pointer">
            <Paragraphs weight={'bold'}>
              <a target="_blank" href="mailto:info@totsafe.es" rel="noreferrer">
                info@totsafe.es
              </a>
            </Paragraphs>
          </div>
        </div>
        <div className="text-center pt-8 pb-4">
          <Paragraphs size="xs">
            © 2022 PLATAFORMA TOT SAFE, SL, PASSEIG DE FABRA I PUIG, 263, 08031
            BARCELONA (BARCELONA)
          </Paragraphs>
        </div>
      </div>
    </div>
  )
}

export default Footer
