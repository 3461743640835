import saludLogo from '../../../../src/infrastructure/assets/static/miniLogos/salud.png'
import vehiculoLogo from '../../../../src/infrastructure/assets/static/miniLogos/vehiculos.png'
import accidenteLogo from '../../../../src/infrastructure/assets/static/miniLogos/accidentes.png'
import inmueblesLogo from '../../../../src/infrastructure/assets/static/miniLogos/inmuebles.png'
import vidaLogo from '../../../../src/infrastructure/assets/static/miniLogos/vida.png'
import robosLogo from '../../../../src/infrastructure/assets/static/miniLogos/robos.png'

export const constantsMiniLogos = [
  { name: 'Salud', icon: saludLogo },
  { name: 'Vehículos', icon: vehiculoLogo },
  { name: 'Accidentes', icon: accidenteLogo },
  { name: 'Inmuebles', icon: inmueblesLogo },
  { name: 'Vida', icon: vidaLogo },
  { name: 'Robos', icon: robosLogo }

]
