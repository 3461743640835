import BannerHeaderHome from '../../components/Molecules/BannerHeaderHome'
import InfoCard from '../../components/Molecules/InfoCard'
import BannerHomeGestion from '../../components/Molecules/BannerHomeGestion'
import TestimonialBanner from '../../components/Molecules/TestimonialsBanner/TestimonialsBanner'
import BannerApps from '../../components/Molecules/BannerApps'
import Faq from '../../components/Molecules/Faq'
import BannerHomeExpense from '../../components/Molecules/BannerHomeExpense'
// const BannerHomeExpense = dynamic(() => import('../../components/Molecules/BannerHomeExpense'), {
//   ssr: false
// })
export default function Home () {
  const testimonials = [
    {
      name: 'Juan Perez',
      location: 'Madrid, España',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      votes: 5
    },
    {
      name: 'Juan Perez',
      location: 'Madrid, España',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      votes: 5
    },
    {
      name: 'Juan Perez',
      location: 'Madrid, España',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
      votes: 5
    }
  ]

  return (
    <div id="home" className=" flex flex-col gap-10 md:gap-20">
      <div id="beanner-header-home" className="w-full">
        <BannerHeaderHome />
      </div>
      <div id="info-card" className="container max-w-[90%] mx-auto">
        <InfoCard />
      </div>
          <div id="banner-home-gestion" className=" w-[100%]">
        <BannerHomeGestion />
      </div>
       <div id="testimonial-home-banner" className="container hidden max-w-[90%] mx-auto ">
        <TestimonialBanner testimonials={testimonials} />
      </div>
     <div id="banner-home-expenses" className="container max-w-[90%] mx-auto ">
        <BannerHomeExpense />
      </div>
    <div id="banner-apps" className="w-full ">
        <BannerApps />
      </div>
     <div id="accordion-faq" className="container max-w-[90%] mx-auto ">
        <Faq />
      </div>
    </div>

  )
}
