import Paragraphs from '../../Atoms/Paragraphs'
import styled from 'styled-components'
import bgVision from '../../../infrastructure/assets/static/images/bg-vision.png'

const BackgroundVision = styled.div`
    background-image: url(${bgVision});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 80%;
    height: 600px ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    margin: 0 auto;

    @media (max-width: 768px) {
        background-image: none;
        height: auto;
        justify-content: start;
        width: 100%;
        padding-left: 30px;
    }
 
`

const BannerMission = () => {
  return (
        <BackgroundVision>
            <div className=' flex flex-col gap-5 max-w-[250px] md:ml-[400px]'>
                <div className='flex flex-col gap-3'>
                <Paragraphs className={'text-primary md:text-secondary'} weight='bold' size='7xl'>Visión</Paragraphs>
                <div id='bg-line' className='w-[200px] h-[3px] bg-pink'></div>
                </div>
                <Paragraphs className={' text-primary md:text-secondary'}>Obtener un alto volumen de pólizas, y así, negociar unas mejores coberturas y precios para nuestros usuarios.</Paragraphs>
            </div>
        </BackgroundVision>
  )
}

export default BannerMission
