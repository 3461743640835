import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useUser } from '../../hooks/useUser'

/**
 *
 * @returns {React.Component}
 */
const AuthContext = ({ children, isPrivate }) => {
  const { token, getInfoUser, user } = useUser()

  useEffect(() => {
    if (token && !user) {
      getInfoUser()
    }
  }, [token])

  if (isPrivate && !token) return null

  return children
}

AuthContext.propTypes = {
  children: PropTypes.node,
  isPrivate: PropTypes.bool
}

export default AuthContext
