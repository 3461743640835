import { useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import PropTypes from 'prop-types'
import Paragraphs from '../../Atoms/Paragraphs'
import TextArea from '../../Atoms/TextArea'
import Button from '../../Atoms/Button'
import { useRating } from '../../../infrastructure/hooks/useRating'
import { useUser } from '../../../infrastructure/hooks/useUser'
import Title from '../../Atoms/Title'
import { toast } from 'react-toastify'
import Loading from '../Loading'

const values = [
  { id: 1, value: 1 },
  { id: 2, value: 2 },
  { id: 3, value: 3 },
  { id: 4, value: 4 },
  { id: 5, value: 5 }
]

const Review = ({ titleRating, detailPoliza, title, subTitle, titleH }) => {
  const [rating, setRating] = useState(1)
  const [comment, setComment] = useState('')

  const { storeRating, loading } = useRating()
  // const { getInsuresUser, insures } = useInsure()
  const { user } = useUser()

  const onSubmit = async () => {
    try {
      const values = {
        comment,
        score: rating,
        user_id: user.id
      }
      if (detailPoliza?.id) {
        values.insurance_id = detailPoliza.id
      }
      const { success } = await storeRating(values)
      if (success) {
        toast.success('Gracias por tu valoración')
      }
    } catch (error) {
      console.log(error)
      toast.error('Error al enviar la valoración')
    }
  }

  const onClickStar = (id) => {
    setRating(id)
  }

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  return (
    <div className={`flex-col flex gap-2 w-full  ${loading ? 'opacity-50' : null} `}>
      {loading && <div className='absolute z-40 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
      <div className='max-w-[600px]'>
        <Title title= {titleH}/>
      <div className='pl-1 pb-2'>
      <Paragraphs className='font-semibold'>{title}</Paragraphs>
      <Paragraphs>{subTitle}</Paragraphs>
      </div>
      <TextArea id='textarea-valoracion' className='max-w-[300px]' value={comment} onChange={handleComment} />
      </div>
      <div className='mt-4'>
      <Paragraphs className="font-semibold pl-1 pb-2">{titleRating}</Paragraphs>
      <div className="flex">
        {values.map((value) => (
          <div key={value.id}>
            <button onClick={() => onClickStar(value.id)}>
              <AiFillStar
                fontSize={30}
                color={rating >= value.id ? '#ffc107' : '#e0e0e0'}
              />
            </button>
          </div>
        ))}
        </div>
      </div>
      <div className='w-[200px]'>
        <Button id='btn-valoracion' onClick={onSubmit} transform='uppercase' mode='orange' label='enviar' size='medium' />
      </div>
    </div>
  )
}

export default Review

Review.propTypes = {
  titleRating: PropTypes.string,
  detailPoliza: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  titleH: PropTypes.string
}
