import googleIcon from '../../../infrastructure/assets/static/images/google_icon.png'
import appleIcon from '../../../infrastructure/assets/static/images/apple_icon.png'

const IconsStore = () => {
  return (
    <div className="flex items-center">
        <a href='https://play.google.com/store/apps/details?id=com.totsafe.org'><img width={150} height={60} src={googleIcon} /></a>
      <a href="https://apps.apple.com/us/app/totsafe-app/id1642527916">
        <img width={130} height={40} src={appleIcon} />
      </a>
    </div>
  )
}

export default IconsStore
