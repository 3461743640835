import Paragraphs from '../../components/Atoms/Paragraphs'
import Page from '../../components/Page'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
const PoliticaPrivacidad = ({ isMobile }) => {
  const { t } = useTranslation()
  return (
    <Page pageTitle={!isMobile && 'Política de privacidad'}>
    <div className="container mx-auto">
        <Paragraphs className="py-3">{t('PLATAFORMA TOT SAFE, SL garantiza la privacidad de los datos de carácter personal aportados, según el Reglamento General de Protección de Datos (RGPD).')}</Paragraphs>
        <Paragraphs className="py-3">{t('La visita a esta página web no supone que el usuario esté obligado a facilitar ninguna información sobre sí mismo, no obstante, durante la visita es posible que se soliciten datos de carácter personal a través de formularios.')}</Paragraphs>
        <Paragraphs className="py-3">{t('Dichos datos serán incorporados a un fichero responsabilidad de PLATAFORMA TOT SAFE, SL y serán utilizados con la finalidad de contestar la solicitud realizada por su parte y para enviarles información de nuestra empresa y servicios por cualquier medio de comunicación. Al enviarnos dicho formulario, usted está aceptando explícitamente la recepción de los mismos. Estos datos se cancelarán a petición suya o una vez satisfecho la finalidad para la que fueron recabados.')}</Paragraphs>
        <Paragraphs className="py-3">{t('Cómo ejercitar los derechos: Los usuarios pueden dirigir una comunicación por escrito a PLATAFORMA TOT SAFE, SL con domicilio en PASSEIG DE FABRA I PUIG, 263, 08031 BARCELONA, o a la dirección de correo electrónico INFO@TOTSAFE.ES incluyendo en ambos casos una fotocopia de su DNI u otro documento identificativo similar, para ejercitar sus derechos, que son los siguientes:')}</Paragraphs>

        <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('Cookies')}</Paragraphs>
        <Paragraphs className="py-3">{t('WWW.TOTSAFE.ES utiliza cookies para facilitar la navegación por dicho portal y para obtener una mayor eficacia y personalización de los servicios ofrecidos a los usuarios. Las cookies empleadas en WWW.TOTSAFE.ES se asocian únicamente con un usuario anónimo y su ordenador, no proporcionan referencias que permitan deducir el nombre y apellidos del usuario y no pueden leer datos de su disco duro ni incluir virus en sus equipos. Asimismo, WWW.TOTSAFE.ES no puede leer las cookies implantadas en el disco duro del usuario desde otros servidores.')}</Paragraphs>
        <Paragraphs className="py-3">{t('El usuario puede libremente decidir acerca de la implantación o no en su disco duro de las cookies empleadas en WWW.TOTSAFE.ES. En este sentido, el usuario puede configurar su navegador para aceptar o rechazar por defecto todas las cookies o para recibir un aviso en pantalla de la recepción de cada cookie y decidir en ese momento su implantación o no en su disco duro. Para ello le sugerimos consultar la sección de ayuda de su navegador para saber cómo cambiar la configuración que actualmente emplea. Aun cuando el usuario configurase su navegador para rechazar todas las cookies o rechazase expresamente las cookies de WWW.TOTSAFE.ES podrá navegar por el portal con el único inconveniente de no poder disfrutar de las funcionalidades del portal que requieran la instalación de alguna de ellas. En cualquier caso, el usuario podrá eliminar las cookies de WWW.TOTSAFE.ES implantadas en su disco duro en cualquier momento, siguiendo el procedimiento establecido en la sección de ayuda de su navegador o entrando en el enlace Política de Cookies de este sitio web.')}</Paragraphs>

        <ul>
          <li>
            <Paragraphs className="py-3">{t('Derecho a solicitar el acceso a los datos personales: usted podrá preguntar a PLATAFORMA TOT SAFE, SL si esta empresa está tratando sus datos.')}</Paragraphs>
          </li>
          <li>
            <Paragraphs className="py-3">{t('Derecho a solicitar su rectificación (en caso de que sean incorrectos) o supresión.')}</Paragraphs>
          </li>
          <li>
            <Paragraphs className="py-3">{t('Derecho a solicitar la limitación de su tratamiento, en cuyo caso únicamente serán conservados por PLATAFORMA TOT SAFE, SL para el ejercicio o la defensa de reclamaciones.')}</Paragraphs>
          </li>
          <li>
            <Paragraphs className="py-3">{t('Derecho a oponerse al tratamiento: PLATAFORMA TOT SAFE, SL dejará de tratar los datos en la forma que usted indique, salvo que por motivos legítimos imperiosos o el ejercicio o la defensa de posibles reclamaciones se tengan que seguir tratando.')}</Paragraphs>
          </li>
          <li>
            <Paragraphs className="py-3">{t('Derecho a la portabilidad de los datos: en caso de que quiera que sus datos sean tratados por otra firma, PLATAFORMA TOT SAFE, SL le facilitará la portabilidad de sus datos al nuevo responsable.')}</Paragraphs>
          </li>

        </ul>
        <Paragraphs className="py-3">{t('Posibilidad de retirar el consentimiento: en el caso de que se haya otorgado el consentimiento para alguna finalidad específica, usted tiene derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.')}</Paragraphs>
        <Paragraphs className="py-3">{t('Cómo reclamar ante la Autoridad de Control: Si un usuario considera que hay un problema con la forma en que PLATAFORMA TOT SAFE, SL está manejando sus datos, puede dirigir sus reclamaciones a la autoridad de protección de datos que corresponda, siendo la Agencia Española de Protección de Datos la indicada en el caso de España.')}</Paragraphs>
        <Paragraphs className="py-3">{t('La negativa a proporcionar los datos supondrá la no prestación o la imposibilidad de acceder al servicio para los que eran solicitados. Usted tiene derecho a recibir respuesta de cualquier pregunta, consulta o aclaración que le surja derivada de este formulario.')}</Paragraphs>

        <Paragraphs className="py-3">{t('Las partes se someten, a su elección, para la resolución de los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales del domicilio del propietario del sitio Web.')}</Paragraphs>

    </div>
</Page>
  )
}

PoliticaPrivacidad.propTypes = {
  isMobile: PropTypes.bool
}

export default PoliticaPrivacidad
