
import ResetPassword from '../../components/Molecules/ResetPassword/ResetPassword'

const ResetPasswordPage = () => {
  return (

  <ResetPassword/>
  )
}

export default ResetPasswordPage
