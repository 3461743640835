/* eslint-disable react/prop-types */
import styled from '@emotion/styled'

export const ContainerPageStyle = styled.div`
display: flex ;
flex-direction: column;
padding-top: ${props => props.paddingTop ? '70px' : '0px'};
min-width: 100%;
`

const ContainerPage = ({ children, paddingTop }) => {
  return (
        <ContainerPageStyle paddingTop={paddingTop}>
            {children}
        </ContainerPageStyle>
  )
}

export default ContainerPage
