import { Container } from '../LoginModal/style'
import { useForm } from 'react-hook-form'
import Paragraphs from '../../Atoms/Paragraphs'
import TextArea from '../../Atoms/TextArea'
import TextInput from '../../Atoms/TextInput'
import Checkbox from '../../Atoms/Checkbox'
import Link from '../../Atoms/Link'
import Button from '../../Atoms/Button/Button'
import { useMutation } from '../../../infrastructure/hooks/useMutation'
import { useState } from 'react'
import { INPUTS_REGISTER } from '../../../infrastructure/constans/inputs/inputs-register'
import { TYPES_PROFILE } from '../../../infrastructure/constans/inputs/types-profile'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actionSetToken } from './../../../infrastructure/redux/actions/user'
import { toast } from 'react-toastify'
import LogoHeader from '../../../infrastructure/assets/static/images/logo_blanco.png'
import Loading from '../Loading'

const RegisterModal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm()
  const [accept, setAccept] = useState(false)
  const [type, setType] = useState(1)
  const [fetchRegister, { loading }] = useMutation('register')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSubmit = async (values) => {
    values.user_type_id = type
    if (!accept) {
      toast.error('Debes aceptar los términos y condiciones')
      return
    }
    // regex for password, min 8 characters, 1 number , word and special character
    // const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    // if (!regex.test(values.password)) {
    //   setError('password', {
    //     type: 'manual',
    //     message: 'La contraseña debe tener al menos 8 caracteres, 1 número, 1 letra y 1 caracter especial'
    //   })
    //   return
    // }

    // regex for password, min 8 characters, 1 number , word and special character
    if (values?.password?.length < 8) {
      setError('password', {
        type: 'manual',
        message: 'La contraseña debe tener al menos 8 caracteres'
      })
      return
    }

    const { success, error, data } = await fetchRegister({
      variables: values,
      method: 'post'
    })
    if (success) {
      dispatch(actionSetToken(data.token, data.user))
      toast.success('Registro correcto')
      navigate('/mi-cuenta/dashboard')
    }
    if (error) {
      const validations = Object.keys(error).flat()
      if (validations) {
        validations.forEach((input) => {
          setError(input, { type: 'validation', message: error[input][0] })
        })
      }
    }
  }

  const onChangeCheckbox = (e) => {
    setAccept(e.target.checked)
  }

  const inputs = INPUTS_REGISTER.filter((input) => {
    if (input.typeFor) {
      return input.typeFor === type
    }

    return input
  })

  return (
    <Container>
      <div className="mx-auto my-4">
        <img className='max-w-[220px]' src={LogoHeader} />
      </div>
      <div
        id="modal_register"
        className={`mx-auto mt-8 pt-8 bg-slate-100 max-w-[90%] w-[800px] flex flex-col justify-center items-center  ${loading ? 'opacity-50' : null}`}
      >
        {loading && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
        <Paragraphs size="2xl" weight="semibold">
          Nuevo usuario
        </Paragraphs>
        <Paragraphs size='xs' className="max-w-[90%]">
          Bienvenido de nuevo !Por favor ingrese sus datos!
        </Paragraphs>
        <form onSubmit={handleSubmit(onSubmit)} className=" w-[80%] mt-8">
          <div className="flex gap-10 mb-8">
            {TYPES_PROFILE.map((i) => (
              <Checkbox
                style={{ flexDirection: 'row-reverse' }}
                type="radio"
                key={i.id}
                id={i.id}
                name={i.id}
                onChange={() => setType(i.id)}
                checked={i.id === type}
                label={i.label}
              />
            ))}
          </div>
          <div className="login-form-inputs gap-6 justify-between  w-full flex flex-wrap ">
            {inputs.map((input) => (
              <div key={input.id} className={`${input.width}`}>
                {input.type === 'textarea'
                  ? (
                  <TextArea
                    error={errors?.[input.name]?.message}
                    {...input}
                    register={register(input.name)}
                  />
                    )
                  : (
                  <TextInput
                    type={input.type}
                    error={errors?.[input.name]?.message}
                    register={register(input.name)}
                    {...input}
                  />
                    )}
              </div>
            ))}
          </div>
          <div className=" mt-8 flex items-center">
            <Checkbox
              type="checkbox"
              onChange={onChangeCheckbox}
              id="politicas"
              name="politicas"
            />
            <Paragraphs size="sm" weight="semibold">
              Acepto los <span className="font-bold">términos</span> y las{' '}
              <span className="font-bold">políticas de privacidad</span>
            </Paragraphs>
          </div>
          <Paragraphs size="xxs" className="text-gray-400 pb-5">
            En el cumplimiento de lo establecido en la normativa vigente de
            Protección de Datos de Carácter Personal, le comunicamos que los
            datos recogidos en el presente formulario, quedarán incorporados y
            serán tratados en el fichero denominado Usuarios Web bajo la
            responsabilidad de PLATAFORMA TOT SAFE, SL con el fin de poder
            prestar sus servicios, así como para mantenerlo informado sobre
            cuestiones relativas a la actividad de la empresa. Le informamos que
            los datos que nos facilite serán tratados de forma leal,
            transparente, adecuada, pertinente, limitada, exacta, actualizada, y
            no se comunicarán o ceden a terceros, exceptuando que esta
            comunicación sea necesaria o pueda suponer una mejora para la
            finalidad de los mismos, siempre acorde a la normativa vigente en
            materia de protección de datos, Reglamento general de protección de
            datos, en el supuesto de que se produzcan cambios en algunos de los
            datos facilitados, con la finalidad de mantener nuestro fichero
            actualizado, le rogamos nos lo comunique por escrito. Le informamos
            de la posibilidad que tienen de ejercer los derechos de acceso,
            rectificación, supresión, oposición, limitación del tratamiento y
            portabilidad de sus datos de carácter personal de forma presencial
            en las oficinas de PLATAFORMA TOT SAFE, SL acompañando copia DNI,
            o bien mediante correo postal a PASSEIG DE FABRA I PUIG, 263, 08031 BARCELONA, o correo electrónico info@totsafe.es
          </Paragraphs>

          <div className="contacto-form-submit flex items-center justify-center">
            <Button
            id={'btn_register'}
              onClick={onSubmit}
              type={'submit'}
              label="Acceder"
              uppercase
              mode="orange"
              size="medium"
            />
          </div>
        </form>
        <div className="bg-primary gap-3 py-8 w-full flex items-center justify-center mt-8 text-center">
          <Paragraphs className="text-secondary">
            ¿Ya tienes una cuenta?
          </Paragraphs>
          <Link
            transform="uppercase"
            url="/login"
            className="text-secondary border-b-4 border-white"
            name="inicia sesión"
          />
        </div>
      </div>
      <div className=" text-secondary mt-2 max-w-[90%] mx-auto w-[800px]">
        <Link url="/" name="Volver" />
      </div>
    </Container>
  )
}

export default RegisterModal
