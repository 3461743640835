import { AiFillStar } from 'react-icons/ai'
import Paragraphs from '../Paragraphs'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

export const TestimonalCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 360px;
  max-width: 100%;
  height: 314px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #459eff1a;
  border-radius: 18px;

  @media (max-width: 383px){
    width:98%;
  }
`

const TestimonalCard = ({ testimonial }) => {
  return (
    <TestimonalCardContainer>
      <div className="flex">
        {[...Array(testimonial?.votes)].map((e, i) => (
          <span key={i}>
            <AiFillStar size="25px" color="#FFAA00" />
          </span>
        ))}
      </div>
      <Paragraphs className= 'w-11/12 text-center text-gray-300' size="sm">
        {testimonial?.description}
      </Paragraphs>
      <div className='flex gap-3 '>
        <div className=''>
          <img className='rounded-full overflow-hidden w-10 h-10' src='https://randomuser.me/api/portraits/med/men/2.jpg'/>
        </div>
        <div className='flex flex-col'>
          <Paragraphs size='sm'>{testimonial.name}</Paragraphs>
          <Paragraphs size='sm' className={'text-gray-400'} weight='light'>{testimonial.location}</Paragraphs>
        </div>
      </div>
    </TestimonalCardContainer>
  )
}

export default TestimonalCard

TestimonalCard.propTypes = {
  testimonial: PropTypes.object.isRequired
}
