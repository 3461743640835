import styled from '@emotion/styled'

export const InversionText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 48%;
    left: 48%;
    transform: translate(-50%, -50%);
    min-width: 200px;
    font-size: 27px;
    
`
