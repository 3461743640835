import InfoAccount from '../../../components/Molecules/InfoAccount'
import LayoutAccount from '../../../components/Molecules/MyAccount/Layout/Layout'
import Layout from '../../../components/Layout'

const InfoAccountPage = () => {
  return (
    <LayoutAccount>
        <InfoAccount/>
    </LayoutAccount>
  )
}

InfoAccountPage.getLayout = function getLayout (page) {
  return <Layout pageTitle={'Mis Datos'}>{page}</Layout>
}

export default InfoAccountPage
