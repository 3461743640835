/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-prototype-builtins */
/* eslint-disable array-callback-return */
import Paragraphs from '../../Atoms/Paragraphs'
import Title from '../../Atoms/Title'
import DonutChart from '../DonutChart/DonutChart'
import { useInsure } from '../../../infrastructure/hooks/useInsure'
import { useEffect, useState } from 'react'
import DonutListChart from '../DonutListChart/DonutListChart'
import { IoIosArrowForward } from 'react-icons/io'
import Link from '../../Atoms/Link'
const COLORS = ['#00bcd4', '#ff9800', '#ff5722', '#9c27b0']

const DashBoard = ({ setShowType }) => {
  const { getInsuresUser, insures } = useInsure()
  const [data, setData] = useState([])
  const [object, setObject] = useState(null)

  const getInsures = async () => {
    await getInsuresUser()
  }

  useEffect(() => {
    getInsures() // solo trae las no caducadas
  }, [])
  // traemos solo el coste y el tipo de póliza
  useEffect(() => {
    if (insures) {
      setObject(insures.map(insure => {
        return {
          name: insure?.insurance_type?.name,
          value: parseFloat(insure.cost)
        }
      }))
    }
  }, [insures])

  // despues de traer el valor y el tipo
  useEffect(() => {
    if (object) {
      const itemsByName = object.reduce(function (map, item) {
        let summarizedItem = map.get(item.name)
        if (!summarizedItem) {
          summarizedItem = item
        } else {
          summarizedItem.value += item.value
        }

        map.set(item.name, summarizedItem)

        return map
      }, new Map())

      setData(Array.from(itemsByName.values()))
    }
  }, [object])

  return (
        <div className='text-primary items-center sm:items-start w-full lg:pl-6 flex flex-col gap-3'>
            <Title title= 'Panel de control' />
            <div className='border-[1px] sm:w-[80%] border-slate-200 p-5 flex flex-col '>
                <div className='mx-auto'>
                <DonutChart data={data} COLORS={COLORS} />
                </div>
                <DonutListChart data={data} COLORS={COLORS} className='flex justify-between lg:px-3 pb-3 border-b-[1px] border-slate-200  ' />
                <Link url={'/mi-cuenta/polizas'}>
                  <div className='text-sky-500 flex items-center gap-2 cursor-pointer'>
                     <Paragraphs className='font-semibold py-3 text-sky-500'>Ver mis pólizas</Paragraphs><IoIosArrowForward />
                  </div>
                </Link>
            </div>
        </div>
  )
}

export default DashBoard
