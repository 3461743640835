/* eslint-disable array-callback-return */
import Paragraphs from '../../Atoms/Paragraphs'
import { useEffect, useState } from 'react'
import AccordionSlide from './components/AccordeonSlide'
import { useFaqs } from '../../../infrastructure/hooks/useFaqs'

const Faq = () => {
  // const [active, setActive] = useState(false)
  const { data } = useFaqs()
  const [array, setArray] = useState([])
  useEffect(() => {
    setArray(data)
  }, [data])

  const onClickFaq = (index) => {
    // setActive(!active)
    setArray(array.map((item, i) => {
      if (i === index) {
        return { ...item, active: !item.active }
      }
      return item
    }
    ))
  }

  return (
    <div className='flex flex-col justify-center gap-10'>
       <Paragraphs className='text-center text-primary' weight='semibold' size= '7xl'>Preguntas Frequentes</Paragraphs>
        <div className='flex flex-col gap-2'>
          {array.map((faq, index) => (
            <AccordionSlide key={index} faq={faq} index={index} onClickFaq={onClickFaq} />
          ))}
        </div>
        </div>
  )
}

export default Faq
