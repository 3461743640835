
export const TYPES_PROFILE = [
  {
    id: 1,
    label: 'Empresa'
  },
  {
    id: 2,
    label: 'Autonomo'
  }
]
