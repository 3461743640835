import PropTypes from 'prop-types'
import Paragraphs from '../Paragraphs'
import { useBtn } from './hooks/useBtn'
import { Container } from './styles'

/**
 *  @author ehernandez
 * @param {propeties} propiedades to component
 * @returns {React.Component}
 */
const Button = ({
  size,
  label,
  shortcut,
  rounded,
  loading,
  type,
  uppercase,
  iconLeft,
  iconRight,
  onClick,
  disabled,
  mode,
  styles,
  form,
  id,
  border,
  transform,
  cy
}) => {
  const { classFromMode } = useBtn({
    mode,
    rounded,
    uppercase,
    border
  })

  return (
    <Container
      data-cy={cy}
      data-testid={id}
      id={id}
      form={form}
      size={size}
      style={{ ...styles }}
      className={`button ${classFromMode(disabled)}`}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
    >
      <div
        className={`flex w-full items-center ${
          shortcut || iconRight || iconLeft
            ? 'justify-between'
            : 'justify-center'
        }`}
      >
        {iconLeft && <div className="flex">{iconLeft}</div>}
        <div>
          <Paragraphs
            className={'button__label text-left'}
            weight="bold"
            size="sm"
            transform={transform}
          >
            {label}
          </Paragraphs>
        </div>
        {shortcut && (
          <div className="ml-2">
            <Paragraphs size="xxs">{shortcut}</Paragraphs>
          </div>
        )}

        {iconRight && <div className="flex">{iconRight}</div>}
      </div>
    </Container>
  )
}
export default Button

Button.propTypes = {
  form: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['submit', 'button']),
  size: PropTypes.oneOf(['mini', 'small', 'medium', 'full']),
  mode: PropTypes.oneOf(['primary', 'secondary', 'transparent', 'white', 'orange']),
  label: PropTypes.string.isRequired,
  shortcut: PropTypes.string,
  rounded: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  uppercase: PropTypes.bool,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  styles: PropTypes.object,
  id: PropTypes.string,
  border: PropTypes.oneOf(['primary', 'secondary', 'none']),
  transform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  cy: PropTypes.string
}

Button.defaultProps = {
  mode: 'primary',
  rounded: false,
  disabled: false,
  size: 'full',
  onClick: null,
  icon: null,
  uppercase: false,
  loading: false,
  type: 'button',
  form: null,
  labelLeft: false,
  boder: 'none'
}
