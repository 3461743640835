/* eslint-disable no-undef */
import { Container } from './style'
import Paragraphs from '../../Atoms/Paragraphs'
import { useForm } from 'react-hook-form'
import TextInput from '../../Atoms/TextInput'
import TextArea from '../../Atoms/TextArea'
import Button from '../../Atoms/Button/Button'
import Link from '../../Atoms/Link'
import { INPUTS_LOGIN } from '../../../infrastructure/constans/inputs/inputs-login'
import { useMutation } from '../../../infrastructure/hooks/useMutation'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actionSetToken } from '../../../infrastructure/redux/actions/user'
import { toast } from 'react-toastify'
import { useState } from 'react'
import LogoHeader from '../../../infrastructure/assets/static/images/logo_blanco.png'
import { ENDPOINT } from '../../../infrastructure/api/configuration'
import Loading from '../Loading'

const LoginModal = () => {
  const { register, handleSubmit, formState: { errors }, setError } = useForm()
  const [fetchLogin, { loading }] = useMutation(ENDPOINT.LOGIN)
  const [errorPasss, setErrorPasss] = useState(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onSubmit = async (values) => {
    const { success, data, error, errorPassword } = await fetchLogin({ variables: values, method: 'post' })
    if (success) {
      toast.success('Inicio de sesión correcto')
      dispatch(actionSetToken(data.token, data.user))
      navigate('/mi-cuenta/dashboard')
    }

    if (error) {
      const validations = Object.keys(error).flat()
      if (validations) {
        validations.forEach(input => {
          setError(input, { type: 'validation', message: error[input][0] })
        })
      }
    }
    if (errorPassword) {
      setErrorPasss(errorPassword)
    }
  }

  return (
    <Container>
      <div className="mx-auto py-4">
        <img className= 'bg-transparent' width={220} src={LogoHeader} />
      </div>
      <div
        id="modal_login"
        className={`mx-auto pt-8 bg-slate-100 w-[600px] max-w-[90%] relative flex flex-col justify-center items-center ${loading ? 'opacity-50' : null}`}
      >
        {loading && <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loading /></div> }
        <Paragraphs size="4xl" weight="semibold">
        ¡Acceso clientes!
        </Paragraphs>
        <Paragraphs className="max-w-[90%]">
          Bienvenido de nuevo ¡Por favor ingrese sus datos!
        </Paragraphs>
        <form onSubmit={handleSubmit(onSubmit)} className=" w-[80%] mt-8">
          <div className="login-form-inputs gap-8  w-full flex flex-col">
            {INPUTS_LOGIN.map((input) => (
              <div key={input.id} className={`${input.width}`}>
                {input.type === 'textarea'
                  ? (
                  <TextArea error={errors?.[input.name]?.message} {...input} register={register(input.name)} />
                    )
                  : (
                  <TextInput type={input.type} errorPasss={errorPasss} error={errors?.[input.name]?.message} register={register(input.name)} {...input} />
                    )}
              </div>
            ))}
          </div>
          <div className=" py-5 flex items-center justify-end">
            <div className='border-sky-600 border-b-[1px]' onClick= {() => setRecuperar(true)}>
            <Link
              className="text-sky-600 "
              url="/reset-password"
              name="Recuperar Contraseña"
            />
            </div>
          </div>
          <div className="contacto-form-submit flex items-center justify-center">
            <Button
            id={'btn_login'}
              onClick={onSubmit}
              type={'submit'}
              label="Acceder"
              uppercase
              mode="orange"
              size="medium"
            />
          </div>
        </form>
        <div className="bg-primary py-8 w-full mt-8 text-center">
          <Link
            transform="uppercase"
            url="/registro"
            className="text-secondary border-b-4 border-white"
            name="¿Aún no estás registrado?"
          />
        </div>
      </div>
      <div className=" text-secondary mt-2 mx-auto max-w-[90%] w-[600px]">
        <Link url="/" name="Volver" />
      </div>
    </Container>
  )
}

export default LoginModal
