import LayoutAccount from '../../../components/Molecules/MyAccount/Layout/Layout'
import DashBoard from '../../../components/Molecules/DashBoard'
import Layout from '../../../components/Layout'

const UserAccount = () => {
  return (
    <LayoutAccount>
         <DashBoard/>
    </LayoutAccount>
  )
}

UserAccount.getLayout = function getLayout (page) {
  return <Layout pageTitle={'Panel de control'}>{page}</Layout>
}

export default UserAccount
