
import styled from '@emotion/styled'

const sizes = {
  mini: {
    height: '30px',
    with: '100px',
    full: '100%'
  },
  small: {
    height: '30px',
    with: '80px'
  },
  medium: {
    height: '40px',
    with: '220px'
  },
  full: {
    height: '50px',
    with: '100%'
  }
}

export const Container = styled.button`
  max-width: ${(props) => sizes[props.size].with};
  height: ${(props) => sizes[props.size].height};
  outline: none !important;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  &.expansible:hover .label {
    text-decoration: underline;
  }
`
