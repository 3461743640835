import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import AuthContext from './infrastructure/context/AuthContext/AuthContext'
import { persistor, store } from './infrastructure/redux/store'
import 'react-toastify/dist/ReactToastify.css'
import Layout from './components/Layout/Layout'
import Home from './pages/Home'
import Login from './pages/login'
import Registro from './pages/registro'
import PoliticaCookies from './pages/politicas'
import PoliticaPrivacidad from './pages/politica-privacidad'
import AvisoLegal from './pages/aviso-legal'
import Contacto from './pages/contacto'
import About from './pages/sobre-nosotros'
import DashBoard from './pages/mi-cuenta/dashboard/dashboard'
import MyAccount from './pages/mi-cuenta/datos/datos'
import Polizas from './pages/mi-cuenta/polizas/polizas'
import Feedback from './pages/mi-cuenta/valoracion'
import DetailPoliza from './pages/mi-cuenta/polizas/detail-polizas'
import ScrollToTop from './infrastructure/components/ScrollToTop'
import ResetPasswordPage from './pages/recuperar-contraseña/reset-pasword'
// import CookieBot from 'react-cookiebot/lib/CookieBot'

/**
 *
 * @returns {React.Component}
 */
const App = () => {
  return (
    <Provider store={store}>

      <PersistGate loading={null} persistor={persistor}>
      {/* <CookieBot domainGroupId={'f56f31d3-23d2-4c93-ab2c-dbd6917362fd'} language="ES"/> */}
          <ToastContainer
            position="top-left"
            autoClose={3000}
            hideProgressBar={true}
            newestOnTop={false}
            pauseOnHover={false}
            closeButton={false}
            rtl={false}
          />
          <BrowserRouter>
          <ScrollToTop>
            <Routes>
            <Route
                  path="politica-privacidad-mobile"
                  element={
                    <AuthContext>
                      <PoliticaPrivacidad isMobile />
                    </AuthContext>
                  }
                />
                                <Route
                  path="politica-privacidad-mobile"
                  element={
                    <AuthContext>
                      <PoliticaPrivacidad isMobile/>
                    </AuthContext>
                  }
                />
                 <Route
                  path="aviso-legal-mobile"
                  element={
                    <AuthContext>
                      <AvisoLegal isMobile/>
                    </AuthContext>
                  }
                />
                    <Route
                  path="politica-cookies-mobile"
                  element={
                    <AuthContext>
                      <PoliticaCookies isMobile />
                    </AuthContext>
                  }
                />
            <Route
                  path="login"
                  element={
                    <AuthContext>
                      <Login />
                    </AuthContext>
                  }
                />
                  <Route
                  path="registro"
                  element={
                    <AuthContext>
                      <Registro />
                    </AuthContext>
                  }
                />
              <Route path="/" element={<Layout />}>
                <Route
                name="home"
                  index
                  element={

                    <AuthContext>
                      <Home />
                    </AuthContext>
                  }
                />
                 <Route
                  path="/sobre-nosotros"
                  element={
                    <AuthContext>
                      <About />
                    </AuthContext>
                  }
                />
                               <Route
                  path="politica-cookies"
                  element={
                    <AuthContext>
                      <PoliticaCookies />
                    </AuthContext>
                  }
                />
                <Route
                  path="politica-privacidad"
                  element={
                    <AuthContext>
                      <PoliticaPrivacidad />
                    </AuthContext>
                  }
                />
                 <Route
                  path="aviso-legal"
                  element={
                    <AuthContext>
                      <AvisoLegal />
                    </AuthContext>
                  }
                />
                 <Route
                  path="contacto"
                  element={
                    <AuthContext>
                      <Contacto />
                    </AuthContext>
                  }
                />
                  <Route
                  path="/mi-cuenta/dashboard"
                  element={
                    <AuthContext>
                      <DashBoard />
                    </AuthContext>
                  }
                />
                <Route
                  path="/mi-cuenta/datos"
                  element={
                    <AuthContext>
                      <MyAccount />
                    </AuthContext>
                  }
                />
                <Route
                  path="/mi-cuenta/polizas"
                  element={
                    <AuthContext>
                      <Polizas />
                    </AuthContext>
                  }
                />
                    <Route
                  path="/mi-cuenta/valoracion"
                  element={
                    <AuthContext>
                      <Feedback />
                    </AuthContext>
                  }
                />
                 <Route
                  path="/mi-cuenta/polizas/:id"
                  element={
                    <AuthContext>
                      <DetailPoliza />
                    </AuthContext>
                  }
                />
                 <Route
                  path="/reset-password"
                  element={
                    <AuthContext>
                      <ResetPasswordPage />
                    </AuthContext>
                  }
                />
                {/* <Route
                  path="redes-sociales"
                  element={
                    <AuthContext isPrivate>
                      <RSSScreen />
                    </AuthContext>
                  }
                /> */}

              </Route>
            </Routes>
            </ScrollToTop>
          </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
