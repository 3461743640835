import styled from '@emotion/styled'

export const ContainerTestimonialsBanner = styled.div`
    display: flex;
    flex-direction: column;
    width: 1271px;
    min-height: 740px;
    position: relative;
    max-width: 100%;
    align-items: center;
    
`

export const CircleBack = styled.div`
    background: url('/images/Group.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 740px;
    height: 100%;
    right: 0;

    @media (max-width: 1274px){
        right: 10px;
    }
    @media (max-width: 1280px){
        display: none;
    }
`
export const CircleBackRed = styled.div`
    background: url('/images/Oval.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 133px;
    height: 133px;
    bottom: 0;
    left: 400px;
    @media (max-width: 1280px){
        display: none;
    }
`
