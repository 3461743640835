import Paragraphs from '../Paragraphs'
import PropTypes from 'prop-types'
import ContentShadow from '../ContentShadow'

const MiniCardLogo = ({ text, url }) => {
  return (
        <ContentShadow id='mini-card-logo' className={'bg-white w-[200px] h-[200px] gap-2 flex items-center justify-center flex-col !p-4 '}>
            <img src={url} height={80} width={80}/>
            <Paragraphs className= 'text-center'>{text}</Paragraphs>
        </ContentShadow>
  )
}

export default MiniCardLogo

MiniCardLogo.propTypes = {
  text: PropTypes.node.isRequired,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
}
