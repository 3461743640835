/* eslint-disable react/prop-types */
import Header from '../Molecules/Header/Header'
import Footer from '../Molecules/Footer'
import PropTypes from 'prop-types'
import { useState } from 'react'
import ContainerPage from '../../infrastructure/styles/ContainerPage'
import { Outlet } from 'react-router-dom'

const HEADER_LIST = [
  { name: 'inicio', url: '/', id: 1 },
  { name: 'sobre nosotros', url: '/sobre-nosotros', id: 2 },
  { name: 'contacto', url: '/contacto', id: 3 }
]

const Layout = ({ title, desciption, pageTitle, children }) => {
  const [paddingTop, setPaddingTop] = useState(false)

  return (
    <>
     <Header setPaddingTop={setPaddingTop} links={HEADER_LIST} />
      <main className='w-full mt-[70px] sm:mt-[0px]'>
        <ContainerPage paddingTop={paddingTop} >
       <Outlet />
        </ContainerPage>
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  desciption: PropTypes.string,
  pageTitle: PropTypes.string
}

export default Layout
