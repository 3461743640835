import styled from '@emotion/styled'
import BackgroundImage from '../../../infrastructure/assets/static/images/background_apps.png'

export const Container = styled.div`
 display: flex;
 background-image: url(${BackgroundImage});
 background-size: cover;
 background-position: center;
 background-repeat: no-repeat;
 width: 100%;
 height:272px;
 align-items: center;
 justify-content: center;
// margin-top: 60px;

  @media (max-width: 770px){
    flex-direction: column;
    height: auto;
  }

`
