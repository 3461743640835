import Page from '../../components/Page'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
const PoliticaCookies = ({ isMobile }) => {
  useEffect(() => {
    fetch('https://consent.cookiebot.com/f56f31d3-23d2-4c93-ab2c-dbd6917362fd/cd.js').then(res => res.text()).then(text => {
      const script = document.createElement('script')
      script.innerHTML = text
      document.body.appendChild(script)
    }
    )
  })
  return (
    <Page pageTitle={!isMobile && 'Política de cookies'}>
    <div className="container mx-auto">
<script id="CookieDeclaration" src="https://consent.cookiebot.com/f56f31d3-23d2-4c93-ab2c-dbd6917362fd/cd.js" type="text/javascript" async></script>    </div>
</Page>)
}

PoliticaCookies.propTypes = {
  isMobile: PropTypes.bool
}

export default PoliticaCookies
