// import ImagePhones from './images/images_phones.png'
import IconsStore from '../../Atoms/IconsStore'
import Paragraphs from '../../Atoms/Paragraphs'
import { Container } from './style'
import Phones from '../../../infrastructure/assets/static/images/images_phones.png'
// import  from 'next/image'

const BannerApps = () => {
  return (
        <Container>
          <div className= 'md:pb-10' >
            <img width={280} height={300} layout='' src={Phones}/>
          </div>
          <div className='flex flex-col text-secondary gap-10'>
              <div>
                <Paragraphs className='md:pl-3 text-center md:text-start' size='xl' >¿Ya lo tienes claro?</Paragraphs>
                <Paragraphs className='md:pl-3  text-center md:text-start' size='7xl' >Descarga la app</Paragraphs>
              </div>
              <div className= 'md:w-[400px] w-[300px]'>
                <IconsStore/>
              </div>
          </div>
        </Container>
  )
}

export default BannerApps
