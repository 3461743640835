import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Label from '../Label'
import { FaEyeSlash, FaEye } from 'react-icons/fa'

const WrapperInput = ({
  children,
  id,
  name,
  label,
  labelClassName,
  classNameContentInput,
  required,
  error,
  description,
  height,
  type,
  flexDirection,
  className,
  hideLabel,
  widthLabel,
  errorPasss,
  style
}) => {
  const border =
    type === 'checkbox' || type === 'radio'
      ? 'border-0'
      : 'border border-gray-200 '
  const paddingLabel = type === 'checkbox' || type === 'radio' ? 'pl-6' : ''
  const width = type === 'checkbox' || type === 'radio' ? 'w-5' : 'w-full'
  const [showPass, setShowPass] = useState(false)

  const onClickEye = (id) => {
    setShowPass(!showPass)
    const input = document.getElementById(id)
    if (input.type === 'password') {
      input.type = 'text'
    } else {
      input.type = 'password'
    }
  }
  return (
    <div
      style={style}
      id={`wrapper-input-${id}`}
      className={`flex relative ${
        description && ' pb-9 md:pb-3'
      } flex-${flexDirection} ${className}`}
    >
      {!hideLabel && (
        <Label
          required={required}
          label={label}
          widthLabel={widthLabel}
          htmlFor={name}
          className={`block  text-sm font-medium text-gray-700 !mb-0 !h-auto ${paddingLabel}`}
        />
      )}
      <div
        className={` relative ${border} ${width}  ${
          height !== 'auto' && 'h-[40px]'
        } ${classNameContentInput}  `}
      >
        {children}
        {error && <div className="text-red-500 text-[12px] mt-1">{error}</div>}
        {errorPasss && type === 'password' && (
          <div className="text-red-500 text-[12px] mt-1">{errorPasss}</div>
        )}
        {description && (
          <div className="text-gray-600  text-[12px] mt-1">{description}</div>
        )}
        {type === 'password' && showPass
          ? (
          <FaEye
            onClick={() => onClickEye(id)}
            className="absolute top-1/2 -translate-y-1/2 cursor-pointer right-4  text-gray-400"
          />
            )
          : (
              type === 'password' && (
            <FaEyeSlash
              onClick={() => onClickEye(id)}
              className="cursor-pointer absolute top-1/2 -translate-y-1/2 right-4  text-gray-400"
            />
              )
            )}
      </div>
    </div>
  )
}

WrapperInput.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  description: PropTypes.string,
  height: PropTypes.string,
  classNameContentInput: PropTypes.string,
  type: PropTypes.string,
  flexDirection: PropTypes.string,
  hideLabel: PropTypes.bool,
  widthLabel: PropTypes.string,
  errorPasss: PropTypes.string,
  style: PropTypes.object
}

WrapperInput.defaultProps = {
  flexDirection: 'col'
}

export default WrapperInput
