export const INPUTS_LOGIN = [
  {
    id: 3,
    label: 'CIF/NIF',
    placeholder: 'Cif',
    name: 'cif',
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'w-12/12'
  },
  {
    id: 7,
    label: 'Contraseña',
    placeholder: 'Contraseña',
    name: 'password',
    secureTextEntry: true,
    required: {
      message: 'El campo es obligatorio'
    },
    width: 'w-12/12',
    type: 'password'
  }
]
