import Page from '../../components/Page'
import { useTranslation } from 'react-i18next'
import Paragraphs from '../../components/Atoms/Paragraphs'
import PropTypes from 'prop-types'
const AvisoLegal = ({ isMobile }) => {
  const { t } = useTranslation()
  return (
        <Page pageTitle={isMobile ? null : 'Aviso legal'}>
            <div className="container mx-auto">
                <Paragraphs>{t('De acuerdo con la exigencia del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), PLATAFORMA TOT SAFE, SL informa de los siguientes datos:')}</Paragraphs>
                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('Titular del sitio web')}</Paragraphs>
                <Paragraphs>
                  {t('Razón Social: PLATAFORMA TOT SAFE, SL')}
                </Paragraphs>
                <Paragraphs>
                  {t('CIF: B16923872')}
                </Paragraphs>
                <Paragraphs>
                  {t('Denominación comercial: TOTSAFE')}
                </Paragraphs>
                <Paragraphs>
                  {t('Domicilio fiscal: CARRER FONT 16, 08960 PASSEIG DE FABRA I PUIG, 263, 08031 BARCELONA (BARCELONA.')}
                </Paragraphs>
                <Paragraphs>
                  {t('Contacto: INFO@TOTSAFE.ES')}
                </Paragraphs>
                <Paragraphs>
                  {t('Registro Mercantil: BARCELONA, Tomo: 46076, Hoja: 508715, Folio: 31, Número de inscripción: 2')}
                </Paragraphs>
                <Paragraphs className="py-6">
                  {t('La presente información regula las condiciones de uso, las limitaciones de responsabilidad y las obligaciones que los usuarios de la página Web que se publica bajo el nombre del dominio WWW.TOTSAFE.ES las cuales los usuarios asumen y se comprometen a respetar.')}
                </Paragraphs>
                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('Cookies')}</Paragraphs>
                <Paragraphs>{t('WWW.TOTSAFE.ES utiliza cookies para facilitar la navegación por dicho portal y para obtener una mayor eficacia y personalización de los servicios ofrecidos a los usuarios. Las cookies empleadas en WWW.TOTSAFE.ES se asocian únicamente con un usuario anónimo y su ordenador, no proporcionan referencias que permitan deducir el nombre y apellidos del usuario y no pueden leer datos de su disco duro ni incluir virus en sus equipos. Asimismo, WWW.TOTSAFE.ES no puede leer las cookies implantadas en el disco duro del usuario desde otros servidores.')}</Paragraphs>
                <Paragraphs>{t('El usuario puede libremente decidir acerca de la implantación o no en su disco duro de las cookies empleadas en WWW.TOTSAFE.ES. En este sentido, el usuario puede configurar su navegador para aceptar o rechazar por defecto todas las cookies o para recibir un aviso en pantalla de la recepción de cada cookie y decidir en ese momento su implantación o no en su disco duro. Para ello le sugerimos consultar la sección de ayuda de su navegador para saber cómo cambiar la configuración que actualmente emplea. Aun cuando el usuario configurase su navegador para rechazar todas las cookies o rechazase expresamente las cookies de WWW.TOTSAFE.ES podrá navegar por el portal con el único inconveniente de no poder disfrutar de las funcionalidades del portal que requieran la instalación de alguna de ellas. En cualquier caso, el usuario podrá eliminar las cookies de WWW.TOTSAFE.ES implantadas en su disco duro en cualquier momento, siguiendo el procedimiento establecido en la sección de ayuda de su navegador o entrando en el enlace Política de Cookies de este sitio web.')}</Paragraphs>

                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('POLÍTICA DE ENLACES')}</Paragraphs>
                <Paragraphs>{t('En caso de que se introduzca por un usuario de Internet un enlace desde su propio Sitio Web al Sitio Web de TOTSAFE, el enlace únicamente vinculará con la página principal de éste último, pero no podrá reproducirla de ninguna forma, y, en caso de que se visualicen los contenidos del Sitio Web con contenidos ajenos al mismo, estos contenidos ajenos no podrán inducir a error, confusión o engaño en los Usuarios sobre la procedencia de sus contenidos, ni suponer un acto de comparación o imitación desleal o un aprovechamiento de la reputación de TOTSAFE.')}</Paragraphs>
                <Paragraphs>{t('No se realizará desde la página que introduce el enlace ningún tipo de manifestación falsa o inexacta sobre TOTSAFE, Empresas Asociadas, colaboradores o empleados, ni se indicará en dicha página que tienen el consentimiento de TOTSAFE para la inserción del enlace, o que TOTSAFE  tiene alguna relación o colaboración con dicha página.')}</Paragraphs>
                <Paragraphs>{t('Salvo en los casos permitidos por la ley o expresamente autorizados por TOTSAFE , siempre que se permita un enlace directo con su Sitio Web, queda prohibida la utilización de cualquier marca o cualquier otro signo distintivo de TOTSAFE dentro de la página donde se encuentre el enlace, salvo que se autorice expresamente.')}</Paragraphs>
                <Paragraphs>{t('La página que establezca el enlace deberá cumplir con la ley y no disponer o enlazar con contenidos ilícitos o contrarios a la moral y a las buenas costumbres.')}</Paragraphs>

                <Paragraphs>{t('Por su parte, el propio Sitio Web incluye dispositivos técnicos de enlace que permiten al Usuario acceder a otras páginas y portales de Internet, actuando TOTSAFE como prestador de servicios de intermediación de conformidad con el artículo 17 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, por lo que sólo será responsable de los contenidos y servicios suministrados en los sitios enlazados en caso de que habiendo tenido conocimiento efectivo, en la forma prevista por la Ley de Servicios de la Sociedad de la Información, de la ilicitud no haya desactivado el enlace.')}</Paragraphs>
                <Paragraphs>{t('La existencia de sitios enlazados no supone que existan acuerdos con los responsables o titulares de los mismos, y TOTSAFE CONSULTING no se hace responsable por los daños producidos por la ilicitud, calidad, desactualización, indisponibilidad, error e inutilidad de los contenidos de dichos sitios, puesto que los desconoce. En cualquier caso, si el Usuario tuviera conocimiento de que los sitios enlazados remiten a páginas cuyos contenidos o servicios son ilícitos o contrarios a la moral deberá ponerlo en conocimiento de TOTSAFE CONSULTING.')}</Paragraphs>
                <Paragraphs>{t('A estos efectos se habilita la dirección de correo electrónico INFO@TOTSAFE.ES, de esta forma, los usuarios deberán comunicar: nombre, dirección, número de teléfono y dirección de correo electrónico, especificación de la supuesta actividad ilícita llevada a cabo en el Portal y hechos o circunstancias que revelan el carácter ilícito de dicha actividad.')}</Paragraphs>
                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('CONDICIONES DE USO')}</Paragraphs>
                <Paragraphs>{t('La utilización de la página otorga la utilización de usuario e implica la aceptación de las condiciones del aviso legal, política de privacidad y condiciones de uso, si el usuario no estuviera conforme, se abstendrá de utilizar la página. El usuario está obligado y se compromete a utilizar la página y los contenidos de conformidad con la legislación vigente, el Aviso Legal, y cualquier otro aviso contenido en esta página, así como con las normas de convivencia, la moral y buenas costumbres generalmente aceptadas.')}</Paragraphs>
                <Paragraphs>{t('El Usuario se obliga y se compromete a no transmitir, difundir o poner a disposición de terceros cualquier contenido de la Página, tales como informaciones, textos, datos, contenidos, mensajes, gráficos, dibujos, archivos de sonido y/o imagen, fotografías, grabaciones, software, logotipos, marcas, iconos, tecnología, fotografías, software, enlaces, diseño gráfico y códigos fuente, o cualquier otro material al que tuviera acceso en su condición de Usuario de la Página, sin que esta enumeración tenga carácter limitativo.')}</Paragraphs>

                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('PROPIEDAD INTELECTUAL E INDUSTRIA')}</Paragraphs>
                <Paragraphs>{t('Todas las marcas, nombres comerciales o signos distintivos de cualquier clase que aparecen en la página son propiedad de PLATAFORMA TOT SAFE, SL o, en su caso, de terceros que han autorizado su uso, sin que pueda entenderse que el uso o acceso al portal y/o a los contenidos atribuya al usuario derecho alguno sobre las citadas marcas, nombres comerciales y/o signos distintivos, y sin que puedan entenderse cedidos al usuario, ninguno de los derechos de explotación que existen o puedan existir sobre dichos contenidos. Y no entiende cedidos ni se autoriza su utilización, en ningún caso, a los usuarios de la página salvo que se obtengan dichos derechos por escrito del legítimo titular de los mismos.')}</Paragraphs>

                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('REDES SOCIALES OFICIALES')}</Paragraphs>
                <Paragraphs>{t('El prestador de servicios de las páginas oficiales de TOTSAFE en las redes sociales Linkedin, Instagram es la entidad PLATAFORMA TOT SAFE, SL (en adelante TOTSAFE) con C.I.F. B16923872 y domicilio social en CARRER FONT 16, 08960 PASSEIG DE FABRA I PUIG, 263, 08031 BARCELONA (BARCELONA y mismo domicilio a efecto de notificaciones.')}</Paragraphs>
                <Paragraphs>{t('El acceso y uso de estas páginas requiere de la aceptación de las condiciones generales de uso de redes sociales y la política de privacidad que más abajo se detalla y se completa con la política y las normas de las plataformas de red social de Linkedin, Instagram.')}</Paragraphs>
                <Paragraphs>{t('Para cualquier consulta o contacto con TOTSAFE los usuarios podrán comunicarse a través del número de teléfono 936 597 701 o de la siguiente dirección electrónica: INFO@TOTSAFE.ES')}</Paragraphs>
                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('EXENCIÓN DE RESPONSABILIDADES')}</Paragraphs>
                <Paragraphs>{t('PLATAFORMA TOT SAFE, SL incluye en su Sitio Web una serie de contenidos sobre sus actividades, productos, estructura, gestión y equipos propios de una Web corporativa. PLATAFORMA TOT SAFE, SL excluye cualquier responsabilidad por los daños y perjuicios de toda naturaleza que pudieran derivarse del acceso a los contenidos, informaciones, publicidad, opciones, conceptos e imágenes facilitados a los usuarios.')}</Paragraphs>
                <Paragraphs>{t('En cualquier caso, PLATAFORMA TOT SAFE, SL no se hace responsable de:')}</Paragraphs>
                <ul>
                  <li>
                    <Paragraphs>{t('El mal que el usuario pueda realizar del Sitio Web.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('La continuidad de los contenidos de la Sitio Web.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('La ausencia de virus y/o demás componentes dañinos en el Sitio Web o en el servidor que los suministra, tanto en lo relativo a la visualización de los contenidos por los usuarios como en la descarga de los mismos.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('os contenidos y servicios prestados por otros Sitios Web a las que pueda tener acceso desde las direcciones de Internet titularidad de PLATAFORMA TOT SAFE, SL.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('El mayor o menor rendimiento de los contenidos albergados en el Sitio Web.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('Los daños o perjuicios que causare cualquier persona, bien a si misma o a terceras, que infringiera las condiciones, normas e instrucciones que PLATAFORMA TOT SAFE, SL establece en este Sitio Web.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('Los daños ocasionados por la vulneración de los sistemas de seguridad del Sitio Web titularidad de PLATAFORMA TOT SAFE, SL.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('El mal funcionamiento de software o “plugins” (descarga que pudiere realizarse desde el link establecido al efecto), que pudiera ser necesario para la visualización o escucha de determinados contenidos albergados en el presente Sitio Web.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('Cualquier acción o actuación que pudiere realizar en relación con la vulneración de derechos de terceros, especialmente, con relación a la imagen, propiedad intelectual o industrial.')}</Paragraphs>
                  </li>
                  <li>
                    <Paragraphs>{t('De la edición, revisión, censura y verificación de los contenidos de las páginas o Sitios de Internet con los que el usuario se conecte a través del motor de búsqueda o de hipervínculos incluidos en el Sitio Web de PLATAFORMA TOT SAFE, SL.')}</Paragraphs>
                  </li>

                </ul>
                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('Exención de responsabilidad por el funcionamiento de la Página Web')}</Paragraphs>
                <Paragraphs>{t('PLATAFORMA TOT SAFE, SL presta sus servicios y contenidos de forma continuada empleando todos los medios técnicos a su alcance para realizar dicha prestación de forma satisfactoria.')}</Paragraphs>
                <Paragraphs>{t('PLATAFORMA TOT SAFE, SL podrá, cuando lo considere conveniente, realizar correcciones, mejoras o modificaciones en la información contenida en su Sitio Web, sin que ello de lugar, ni derecho a ninguna reclamación o indemnización, ni implique reconocimiento de responsabilidad alguna.')}</Paragraphs>
                <Paragraphs>{t('PLATAFORMA TOT SAFE, SL no se hace responsable por los daños y perjuicios de toda naturaleza que pudieran derivarse de la disponibilidad y continuidad técnica del funcionamiento de su Sitio Web. En cualquier caso, PLATAFORMA TOT SAFE, SL, llevará a cabo todas las actuaciones necesarias para restablecer sus servicios en caso de fallo técnico.')}</Paragraphs>

                <Paragraphs className='my-6' weight={'bold'} transform='uppercase'>{t('LEGISLACIÓN Y JURISDICCIÓN')}</Paragraphs>
                <Paragraphs>{t('PLATAFORMA TOT SAFE, SL se reserva la facultad de presentar las acciones civiles o penales que considere oportunas por la utilización indebida de su sitio web y contenidos, o por el incumplimiento de las presentes condiciones.')}</Paragraphs>
                <Paragraphs>{t('En caso de existir discrepancia entre lo establecido en el presente Aviso Legal y las Condiciones Particulares de cada servicio específico, prevalecerá lo dispuesto en éstas últimas.')}</Paragraphs>
                <Paragraphs>{t('En el caso de que cualquier disposición o disposiciones contenidas en el presente Aviso Legal fueran consideradas nulas o inaplicables, en su totalidad o en parte, por cualquier Juzgado, Tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará al resto de disposiciones del presente Aviso Legal ni a las Condiciones Particulares de los diferentes servicios del presente Sitio Web.')}</Paragraphs>
                <Paragraphs>{t('El no ejercicio o ejecución, por parte de PLATAFORMA TOT SAFE, SL de cualquier derecho o disposición contenido en estas Condiciones Generales no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo escrito por su parte.')}</Paragraphs>
                <Paragraphs>{t('La relación entre el usuario y el prestador se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia las partes podrán someter sus conflictos a arbitraje o acudir a la jurisdicción ordinaria cumpliendo con las normas sobre jurisdicción y competencia al respecto. PLATAFORMA TOT SAFE, SL tiene su domicilio en PASSEIG DE FABRA I PUIG, 263, 08031 BARCELONA (BARCELONA, España.')}</Paragraphs>
            </div>
        </Page>
  )
}

AvisoLegal.propTypes = {
  isMobile: PropTypes.bool
}
export default AvisoLegal
